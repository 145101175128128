import styled from "styled-components";
import LoggedInBoilerplate from "../../LoggedInBoilerplate";
import { useEffect, useState } from "react";
import LoadingSection from "../../../../helperComponents/LoadingSection";
import { serverLine } from "../../../../../controllers/serverLine";
import getUrlQuery from "../../../../../controllers/getUrlQuery";
import formatDate from "../../../../../controllers/formatDate";
import goTo from "../../../../../controllers/goTo";
import getImageURL from "../../../../../controllers/getImageURL";
import capitalizeFirstLetter from "../../../../../controllers/capitalizeFirstLetter";
import HealthReportButton from "../../HealthReportButton";
import PrimaryButton from "../../../../helperComponents/PrimaryButton";
import { useTranslation } from "react-i18next";
import ExactSlotTime from "../scheduleCheckup/ExactSlotTime";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  /* align-items: center; */
  width: 100%;
  justify-content: space-between;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 50px;
    justify-content: flex-start;
  }
`;

const Title = styled.div`
  font-weight: 900;
  font-size: 28px;
  color: var(--accentColor);
`;

const Medium = styled.div`
  /* font-weight: 900; */
  opacity: 0.8;
  font-size: 19px;
  /* color: var(--accentColor); */
`;

const SmallTitle = styled.div`
  font-size: 25px;
  margin-top: 20px;
  margin-bottom: 5px;
  color: var(--accentColor);
`;

const ReportData = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  width: 600px;
  gap: 10px;
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  width: 50vw;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 20px;
`;

export default function HealthReportPage() {
  const [item, setItem] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    let urlQuery = getUrlQuery();
    serverLine
      .get(`/health-checkup-booking/?itemID=${urlQuery.itemID}`)
      .then(setItem);
  }, []);

  if (!item)
    return (
      <LoggedInBoilerplate>
        <LoadingSection />
      </LoggedInBoilerplate>
    );

  let reportFileButton = null;
  let reportData = null;

  if (item.reportFile) {
    reportFileButton = (
      <PrimaryButton
        style={{ width: "300px", borderRadius: "10px" }}
        onClick={goTo(getImageURL(item.reportFile))}
      >
        {t("healthReportItemOpenReport")}
      </PrimaryButton>
    );
  }

  if (item.reportData) {
    reportData = (
      <>
        <HealthReportButton icon="/weight.png">
          {t("healthReportItemWeight")} <br /> {item.reportData.weight} lbs
        </HealthReportButton>
        <HealthReportButton icon="/lean-body-mass.png">
          {t("healthReportItemLearnBodyMass")}
          <br /> {item.reportData.leanBodyMass} lbs
        </HealthReportButton>
        <HealthReportButton icon="/muscle-mass.png">
          {t("healthReportItemMuscleMass")}
          <br /> {item.reportData.muscleMass} lbs
        </HealthReportButton>
        <HealthReportButton icon="/body-water.png">
          {t("healthReportItemBodyWater")}
          <br /> {item.reportData.bodyWater} lbs
        </HealthReportButton>
        <HealthReportButton icon="/body-fat-mass.png">
          {t("healthReportItemBodyFatMass")}
          <br /> {item.reportData.bodyFatMass} lbs
        </HealthReportButton>
        <HealthReportButton icon="/inorganic-salts.png">
          {t("healthReportItemInorganicSalt")}
          <br /> {item.reportData.inorganicSalts} lbs
        </HealthReportButton>
        <HealthReportButton icon="/protein.png">
          {t("healthReportItemProtein")}
          <br /> {item.reportData.protein} lbs
        </HealthReportButton>
      </>
    );
  }

  return (
    <LoggedInBoilerplate>
      <Container>
        <Left>
          <Title>
            {t("healthReportItemTitle")} {formatDate(item.date)}{" "}
          </Title>

          <Info>
            <Medium>
              {t("healthReportItemDate")}: {formatDate(item.date)}
            </Medium>

            <Medium>
              {t("healthReportItemTime")}:{" "}
              <ExactSlotTime slot={item.slot} hour={item.hour} />
            </Medium>

            <Medium>
              {t("healthReportItemStatus")}:{" "}
              {capitalizeFirstLetter(item.status.toLowerCase())}
            </Medium>

            <Medium>
              <SmallTitle> {t("healthReportItemRemark")}</SmallTitle>

              {item.remark ? item.remark : "Remark Not Available"}
            </Medium>
          </Info>

          {reportFileButton}
        </Left>

        <ReportData>{reportData}</ReportData>
      </Container>
    </LoggedInBoilerplate>
  );
}
