import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Img = styled.img`
  height: 40px;
  width: 40px;
  cursor: pointer;
  object-fit: contain;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  align-items: center;
  border-radius: 10px;
  height: 163px;
  width: 257px;
  color: var(--accentColor);
  background: linear-gradient(45deg, #d4cfcf1c, #000000, #c8c8c824);
  border: 1px solid #d1da303b;
  cursor: pointer;
`;

const Text = styled.div`
  font-size: 15px;
  text-align: center;
  width: 60%;
`;

export default function HealthReportButton({ icon, children }) {
  const { t } = useTranslation();

  return (
    <Container>
      <Img src={"/health-report-icons/" + icon} />
      <Text>{children}</Text>
    </Container>
  );
}
