import CustomButton from "../helperComponents/CustomButton";
import { styled } from "styled-components";
import { serverLine } from "../../controllers/serverLine";
import { useContext, useState } from "react";

import Context from "../../Context";
import LoadingSection from "../helperComponents/LoadingSection";

const Container = styled.div`
  padding: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
`;

let typeVsPrice = {
  CONTRACT: {
    price: 5,
    value: 15,
    fieldName: "contractToken",
  },

  STORAGE: {
    price: 1,
    value: 100,
    fieldName: "storageTokens",
  },
};

const Title = styled.div`
  font-size: 21px;
  opacity: 0.7;
  text-transform: capitalize;
`;
//100 tokens

export default function PopupBuyTokens({ type }) {
  const { loggedInUser } = useContext(Context);
  const [loading, setLoading] = useState(false);
  let data = typeVsPrice[type];

  if (loading || !loggedInUser) return <LoadingSection />;

  let tokensLeft = loggedInUser[data.fieldName];
  return (
    <Container>
      <Title>
        {tokensLeft ? tokensLeft : 0} {type.toLowerCase()} tokens left
      </Title>
      <CustomButton
        style={{ textTransform: "capitalize" }}
        onClick={makePurchase}
      >
        Get {data.value} Tokens for ${data.price}
      </CustomButton>
    </Container>
  );

  async function makePurchase() {
    setLoading(true);
    let session = await serverLine.get(
      "/stripe-checkout-session/?type=" + type
    );
    window.location = session;
  }
}
