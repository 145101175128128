import { useTranslation } from "react-i18next";
import ListPage from "../ListPage";
import OrderCard from "./OrderCard";

export default function MyOrdersPage() {
  const { t } = useTranslation();

  return (
    <ListPage
      path="/my-orders"
      title={t("myOrdersTitle")}
      CardComponent={OrderCard}
    />
  );
}
