import { useTranslation } from "react-i18next";
import AdminCardsList from "../AdminCardsList";
import ManageHealthCheckupCard from "./ManageHealthCheckupCard";

export default function ManageHealthCheckupsPage() {
  const { t } = useTranslation();

  return (
    <AdminCardsList
      title={t("adminPageHealthCheckupTitle")}
      path="health-checkups"
      CardComponent={ManageHealthCheckupCard}
    />
  );
}
