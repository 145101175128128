import React from "react";
import styled from "styled-components";
import getImageURL from "../../../../controllers/getImageURL";
import SemanticButton from "../../../helperComponents/SemanticButton";
import { useTranslation } from "react-i18next";

const Card = styled.div`
  background: #000000;
  border-radius: 15px;
  width: 600px;
  height: 340px;
  margin-left: 25px;
  overflow: hidden;
  color: white;
  position: relative;
  pointer-events: none;
  transition: all 0.25s ease-in-out;
  cursor: pointer;

  @media (max-width: 900px) {
    width: 85vw;
    height: 240px;
    margin-left: 2.5vw;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const InfoContainer = styled.div`
  padding: 20px;
  position: absolute;
  bottom: 0;
  background-color: var(--bgColor);
  width: 100%;
  left: 0;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #c9d400;
  margin: 0;
`;

const handleDragStart = (e) => e.preventDefault();

export default function AnnouncementSlideShowCard({ item }) {
  const { t } = useTranslation();

  return (
    <Card handleDragStart={handleDragStart}>
      <SemanticButton href={`/announcement/?itemID=${item._id}`}>
        <ImageContainer>
          <Image src={getImageURL(item.image)} alt="" />
        </ImageContainer>
        <InfoContainer>
          <Title>{item.title}</Title>
        </InfoContainer>
      </SemanticButton>
    </Card>
  );
}
