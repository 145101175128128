import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PrimaryButton from "../../../../helperComponents/PrimaryButton";
import { AiOutlineClockCircle } from "react-icons/ai";
import { FaPersonMilitaryPointing } from "react-icons/fa6";
import { CgLock } from "react-icons/cg";
import { GoClockFill } from "react-icons/go";
import getImageURL from "../../../../../controllers/getImageURL";
import { serverLine } from "../../../../../controllers/serverLine";
import LoadingSection from "../../../../helperComponents/LoadingSection";
import { useTranslation } from "react-i18next";
import goTo from "../../../../../controllers/goTo";
import SemanticButton from "../../../../helperComponents/SemanticButton";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 40px 0;
  background-color: #000;
  color: #fff;
  width: 100%;
  font-family: Arial, sans-serif;

  @media (max-width: 900px) {
    flex-direction: column-reverse;
    justify-content: flex-start;
    gap: 90px;
  }
`;

const InfoSection = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 50px;

  @media (max-width: 900px) {
    width: 80vw;
  }
`;

const Title = styled.h1`
  font-size: 36px;
  color: #c9d400;
  margin: 0;
`;

const CoachAndDuration = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 900px) {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;

const IconText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 30px;
  color: var(--accentColor);
  font-size: 18px;

  @media (max-width: 900px) {
    margin: 0;
  }
`;

const Icon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  /* display: inline-block; */
  margin-right: 10px;
`;

const Subtitle = styled.div`
  font-size: 24px;
  color: #c9d400;
  font-weight: 900;
  /* margin-bottom: 10px; */
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  font-size: 16px;
  margin-bottom: 8px;
  text-transform: capitalize;

  &::before {
    content: "•";
    color: #c9d400;
    margin-right: 8px;
  }
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;

  @media (max-width: 900px) {
    gap: 20px;
  }
`;

const Image = styled.img`
  width: 400px;
  border-radius: 0;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const SeatsLeft = styled.div`
  font-size: 18px;
  padding: 20px;
  color: #c9d400;
`;

const ImageSection = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--translucent);
  border: var(--highlightBorder);
  border-radius: 10px;
  overflow: hidden;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

function ActivityDetails({ item, bookingData }) {
  const [isBooked, setIsBooked] = useState(bookingData);
  const [state, setState] = useState(item);
  const [bookingInProgress, setBookingInProgress] = useState(false);

  const { t } = useTranslation();

  let bookButton = (
    <PrimaryButton
      onClick={doBooking}
      style={{ width: "100%", borderRadius: "10px" }}
    >
      Réserver
    </PrimaryButton>
  );

  let cancelBookingButton = (
    <PrimaryButton
      onClick={doBooking}
      style={{
        width: "100%",
        borderRadius: "10px",
        background: "#c95e48",
        boxShadow: "rgb(96 12 12) 5px 6px 0px",
      }}
    >
      {t("activityItemCancelBooking")}
    </PrimaryButton>
  );

  let theButton = isBooked ? cancelBookingButton : bookButton;

  if (bookingInProgress) theButton = <LoadingSection />;

  let showReportButton = null;

  if (bookingData) {
    if (bookingData.reportFile) {
      theButton = null;

      showReportButton = (
        <PrimaryButton
          onClick={goTo(getImageURL(bookingData.reportFile))}
          style={{
            width: "100%",
            borderRadius: "10px",
          }}
        >
          {t("activityItemShowReport")}
        </PrimaryButton>
      );
    }
  }

  return (
    <Container>
      <InfoSection>
        <Section>
          <Title>{item.activityType.title}</Title>
          <CoachAndDuration>
            <SemanticButton
              onClick={goTo("/employee/?itemID=" + item.employee._id)}
            >
              <IconText>
                <Icon>
                  <FaPersonMilitaryPointing />
                </Icon>
                {t("activityItemCoach")}: {item.employee.name}
              </IconText>
            </SemanticButton>

            <IconText>
              <Icon>
                <GoClockFill />
              </Icon>
              {t("activityItemDuration")}: {item.duration} min
            </IconText>
          </CoachAndDuration>
        </Section>

        <Section>
          <Subtitle> {t("activityItemDescription")}</Subtitle>
          <Text>{item.activityType.description}</Text>
        </Section>

        <Section>
          <Subtitle>{t("activityItemDetails")} </Subtitle>
          <List>
            <ListItem>
              {t("activityItemParticipationType")}:{" "}
              {item.participationType ? t(item.participationType) : "N/A"}
            </ListItem>
            <ListItem>
              {t("activityItemMembershipType")}:{" "}
              {item.membershipType
                ? item.membershipType.join(", ").toLowerCase()
                : "N/A"}
            </ListItem>
          </List>
        </Section>

        {bookingData ? (
          <Section>
            {bookingData.remark ? (
              <>
                <Subtitle>{t("activityItemRemark")}</Subtitle>
                <List>
                  <ListItem>{bookingData.remark}</ListItem>
                  {bookingData.remark2 ? (
                    <ListItem>{bookingData.remark2}</ListItem>
                  ) : null}
                  {bookingData.remark3 ? (
                    <ListItem>{bookingData.remark3}</ListItem>
                  ) : null}
                </List>
              </>
            ) : null}
          </Section>
        ) : null}

        <Section>
          <Subtitle>{t("activityItemAboutCoach")}</Subtitle>
          <List>
            <ListItem>{item.employee.name}</ListItem>
            <ListItem>
              {t("activityItemDescription")}: {item.employee.description}
            </ListItem>
          </List>
        </Section>
      </InfoSection>

      <RightSection>
        <ImageSection>
          <Image src={getImageURL(item.activityType.image)} />
          <SeatsLeft>{state.seats - state.seatsBooked} Seats Left</SeatsLeft>
        </ImageSection>

        {showReportButton}
        {theButton}
      </RightSection>
    </Container>
  );

  async function doBooking() {
    setBookingInProgress(true);

    await serverLine.post("/activity-booking", {
      activityID: item._id,
      action: isBooked ? "UNBOOK" : "BOOK",
    });

    let newState = { ...state };

    if (isBooked) {
      newState.seatsBooked--;
    } else {
      newState.seatsBooked++;
    }

    setState(newState);

    window.popupAlert("Done");

    setIsBooked(isBooked ? false : true);
    setBookingInProgress(false);
  }
}

export default ActivityDetails;
