import { useTranslation } from "react-i18next";
import BigToggle from "../../../../helperComponents/BigToggle";

export default function ScheduleMinutesPicker({
  value,
  onChange,
  hourValue,
  label,
}) {
  const { t } = useTranslation();
  if (!hourValue) return null;

  let allHours = getMins();

  return (
    <BigToggle
      title={label ? label : "Select Minutes"}
      options={allHours.map((item) => getItem(item))}
    ></BigToggle>
  );

  function getItem(item) {
    return {
      onClick: () => {
        onChange(item.value);
      },
      isSelected: value === item.value,
      label: item.label,
    };
  }

  function addAdditionalZero(num) {
    num = num.toString();

    if (num.length == 1) return `0${num}`;
    return num;
  }

  function getMins(startHour, endHour) {
    let timeSlots = [];

    // Loop through each hour from startHour to endHour
    for (let mins = 5; mins < 60; mins += 5) {
      // Format the slot as "hour - hour+1"

      let slot = `${addAdditionalZero(hourValue)} : ${formatMinutes(mins)}`;

      timeSlots.push({ label: slot, value: mins });
    }

    return timeSlots;
  }

  function formatMinutes(val) {
    val = val.toString();

    if (val.length == 1) return `0${val}`;

    return val;
  }
}
