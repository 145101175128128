import styled from "styled-components";
import WithBackground from "../../../../core/boilerplate/WithBackground";
import LoggedInHeader from "../../LoggedInHeader";
import InputCard from "../../../../helperComponents/InputCard";
import ScheduleDatePicker from "./ScheduleDatePicker";
import { useEffect, useState } from "react";
import ScheduleHourPicker from "./ScheduleHourPicker";
import ScheduleSlotPicker from "./ScheduleSlotPicker";
import LoggedInBoilerplate from "../../LoggedInBoilerplate";
import submitData from "../../../../../controllers/request/submitData";
import LoadingSection from "../../../../helperComponents/LoadingSection";
import { serverLine } from "../../../../../controllers/serverLine";
import ExistingCheckupBooking from "./ExistingCheckupBooking";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  align-items: center;
  gap: 50px;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-bottom: 20px;
`;

export default function ScheduleCheckupPage() {
  const [date, setDate] = useState(null);
  const [hour, setHour] = useState(null);
  const [slot, setSlot] = useState(null);
  const [loading, setLoading] = useState(false);
  const [upcomingChecksUps, setUpcomingChecksUps] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    resetAndDoQuery();
  }, []);

  async function resetAndDoQuery() {
    setDate(null);
    setHour(null);
    setSlot(null);
    setLoading(true);
    let newData = await serverLine.get("/my-upcoming-health-checkups");
    setUpcomingChecksUps(newData);
    setLoading(false);
  }

  if (loading || !upcomingChecksUps)
    return (
      <LoggedInBoilerplate showBackgroundImage>
        <LoadingSection />
      </LoggedInBoilerplate>
    );

  if (upcomingChecksUps.length)
    return (
      <LoggedInBoilerplate showBackgroundImage>
        <InputCard
          style={{ width: "38vw" }}
          textContainerStyle={{ alignItems: "flex-start", width: "100%" }}
          title={t("scheduleCheckupYouHaveUpcomingCheckup")}
          desc={t("scheduleCheckupYouHaveGreatExperience")}
        >
          <ExistingCheckupBooking value={upcomingChecksUps[0]} />
        </InputCard>
      </LoggedInBoilerplate>
    );

  return (
    <LoggedInBoilerplate showBackgroundImage>
      <Container>
        <InputCard
          style={{ width: "38vw" }}
          textContainerStyle={{ alignItems: "flex-start", width: "100%" }}
          title={t("scheduleCheckupTitle")}
          desc={t("scheduleCheckupSubtitle")}
          submitButtonText={t("scheduleCheckupButton")}
          onSubmit={onSubmit}
        >
          <Inputs>
            <ScheduleDatePicker
              forHealthCheckup={true}
              value={date}
              onChange={setDate}
              label={t("scheduleCheckupSelectDate")}
            />
            <ScheduleHourPicker
              value={hour}
              onChange={setHour}
              label={t("scheduleCheckupSelectTime")}
            />
            <ScheduleSlotPicker
              date={date}
              hour={hour}
              value={slot}
              onChange={setSlot}
              label={t("adminHealthCheckupsSelectSlot")}
            />
          </Inputs>
        </InputCard>

        <br />
        <br />
        <br />
      </Container>
    </LoggedInBoilerplate>
  );

  function onSubmit() {
    if (!hour || !date || !slot)
      return window.popupAlert("All fields are required.");

    submitData({
      method: "post",
      path: "/health-checkup-booking",
      data: { date, hour, slot },
      onSuccess: () => {
        resetAndDoQuery();
        // window.location.reload();
      },
      setLoading,
    });
  }
}
