import { useContext, useEffect, useState } from "react";
import Section from "../../helperComponents/Section";
import LoggedInBoilerplate from "./LoggedInBoilerplate";
import LoadingSection from "../../helperComponents/LoadingSection";
import { serverLine } from "../../../controllers/serverLine";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import CustomMasonry from "../../helperComponents/CustomMasonry";
import Context from "../../../Context";
import LoggedOutBoilerplate from "../loggedOut/LoggedOutBoilerplate";

const Top = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const TopLeft = styled.div`
  font-size: 27px;
  color: var(--accentColor);

  @media (max-width: 900px) {
    font-size: 17px;
  }
`;

const TopRight = styled.div`
  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;

  @media (max-width: 900px) {
    gap: 20px;
    margin-top: 20px;
  }
`;

export default function ListPage({
  path,
  CardComponent,
  title,
  filterComponent,
  colsOnMobile = 1,
  filterFunction,
}) {
  const { loggedInUserID } = useContext(Context);

  const [items, setItems] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    serverLine.get(path).then(setItems);
  }, []);

  let MainContainer = LoggedInBoilerplate;

  if (!loggedInUserID) MainContainer = LoggedOutBoilerplate;

  if (!items)
    return (
      <MainContainer>
        <LoadingSection />
      </MainContainer>
    );

  let filteredItems = items;

  if (filterFunction) {
    filteredItems = filterFunction(filteredItems);
  }

  let allItems = filteredItems.map((item) => <CardComponent item={item} />);

  return (
    <MainContainer>
      <Container>
        <Top>
          <TopLeft>{title}</TopLeft>
          {filterComponent ? <TopRight>{filterComponent}</TopRight> : null}
        </Top>

        <CustomMasonry list={allItems} maxCol={4} colsOnMobile={colsOnMobile} />
      </Container>
    </MainContainer>
  );
}
