import styled from "styled-components";
import { useContext, useRef } from "react";
import Context from "../../../Context.js";
import BrandContainer from "../../brand/BrandContainer.js";
import LoggedInHeaderButton from "./LoggedInHeaderButton.js";
import MembershipMiniInfo from "./MembershipMiniInfo.js";
import { useTranslation } from "react-i18next";
import HelloUserSection from "./announcements/HelloUserSection.js";
import MyActivitiesHeaderButton from "./MyActivitiesHeaderButton.js";

const TopPart = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 90px;
  margin-bottom: 60px;

  @media (max-width: 900px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;

const Links = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
  padding: 0px 35px;
  border-radius: 10px;
  height: 65px;
  background: linear-gradient(45deg, #d4cfcf1c, #000000, #c8c8c824);
  border: 1px solid #d1da303b;
  /* background: var(--mainGradient); */

  @media (max-width: 900px) {
    position: fixed;
    bottom: 10px;
    left: 10px;
    z-index: 100;
    width: calc(100vw - 20px);
    background-color: #000;
    gap: 0;
    justify-content: space-between;
    box-shadow: 2px -9px 20px 18px #000000;
    border-radius: 100px;
  }
`;

const Right = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const TopPartMobile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
`;

const RowMobile = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export default function LoggedInHeader() {
  const { loggedInUser, isMobile } = useContext(Context);
  const { t } = useTranslation();

  let links = (
    <Links>
      <LoggedInHeaderButton
        link="/"
        icon="home.png"
        activeIcon={"home-active.png"}
      />
      <LoggedInHeaderButton
        link="/membership"
        icon="membership.png"
        activeIcon={"membership-active.png"}
      />
      <LoggedInHeaderButton
        link="/shopping"
        icon="shopping.png"
        activeIcon={"shopping-active.png"}
      />
      <LoggedInHeaderButton
        badgeCount={getTotalCartItems()}
        link="/cart"
        icon="cart.png"
        activeIcon={"cart-active.png"}
      />
      <LoggedInHeaderButton
        link="/settings"
        icon="settings.png"
        activeIcon={"settings-active.png"}
      />
    </Links>
  );

  if (isMobile)
    return (
      <TopPartMobile>
        <RowMobile>
          <HelloUserSection />

          <BrandContainer />
        </RowMobile>

        <RowMobile>
          <MembershipMiniInfo />

          <MyActivitiesHeaderButton />
        </RowMobile>

        {links}
      </TopPartMobile>
    );

  return (
    <TopPart>
      <BrandContainer />

      <Right>
        <MembershipMiniInfo />
        {links}
      </Right>
    </TopPart>
  );

  function getTotalCartItems() {
    if (!loggedInUser.cart) return 0;

    let totalItems = 0;

    for (let itemID in loggedInUser.cart) {
      let item = loggedInUser.cart[itemID];
      totalItems += item.quantity;
    }

    return totalItems;
  }
}
