import React, { useContext } from "react";
import styled from "styled-components";

import { useTranslation } from "react-i18next";
import SemanticButton from "../../../helperComponents/SemanticButton";
import getImageURL from "../../../../controllers/getImageURL";
import formatDate from "../../../../controllers/formatDate";
import PrimaryButton from "../../../helperComponents/PrimaryButton";
import Context from "../../../../Context";
import AddOrEditForm from "../../loggedIn/admin/AddOrEditForm";

const Card = styled.div`
  background: #111111;
  border-radius: 15px;
  width: 350px;
  overflow: hidden;
  color: white;
  position: relative;
  transition: all 0.25s ease-in-out;

  border: 1px solid rgba(255, 255, 255, 0.1);

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 250px;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Badge = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: #303030;
  padding: 5px 15px;
  border-radius: 20px;
  font-size: 14px;
  color: #c9d400;
  font-weight: bold;
`;

const InfoContainer = styled.div`
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #c9d400;
  margin: 0;
  padding: 0;
`;

const Subtitle = styled.div`
  font-size: 16px;
  color: #808080;
`;

const Badge2 = styled(Badge)`
  left: 15px;
  right: unset;
  background: #c9d400;
  color: #303030;
`;

export default function BuyActivityCard({ item }) {
  const { setForm } = useContext(Context);
  const { t } = useTranslation();

  return (
    <Card>
      <ImageContainer>
        <Image src={getImageURL(item.activityType.image)} alt="" />
      </ImageContainer>
      <InfoContainer>
        <Title>{item.activityType.title}</Title>

        <Subtitle>
          {formatDate(item.date)} @ {item.hour}:{item.mins}
        </Subtitle>
        <Subtitle>
          {" "}
          {t("upcomingActivitiesCoach")}: {item.employee.name}
        </Subtitle>
        <Subtitle>
          {" "}
          {t("upcomingActivitiesSeatsLeft")} Left: {item.seats}
        </Subtitle>

        <PrimaryButton
          style={{ width: "100%", borderRadius: "10px", marginTop: "10px" }}
          onClick={doBooking}
        >
          Purchase {item.priceForNonMember} CFA{" "}
        </PrimaryButton>
      </InfoContainer>
    </Card>
  );

  function doBooking() {
    let fields = [
      {
        type: "STRING",
        name: "email",
        label: "Email",
      },
    ];

    setForm({
      title: t("bookActivity"),
      component: (
        <AddOrEditForm
          arrayItems={[]}
          setArrayItems={() => {
            window.popupAlert(t("checkEmailForFurtherDetails"));
          }}
          additionalData={{ activityID: item._id }}
          fields={fields}
          path="/buy-activity"
        />
      ),
    });
  }
}
