import { useTranslation } from "react-i18next";
import AdminCardsList from "../AdminCardsList";
import ManageActivityTypeCard from "./ManageActivityTypeCard";

export default function ManageActivityTypePage() {
  const { t } = useTranslation();

  return (
    <AdminCardsList
      title={t("adminPageActivityTypeTitle")}
      path="activity-types"
      CardComponent={ManageActivityTypeCard}
      allowCreation={true}
    />
  );
}
