import styled from "styled-components";
import LoggedInHeader from "./LoggedInHeader";
import WithBackground from "../../core/boilerplate/WithBackground";
import Footer from "../loggedOut/landingPage/Footer";
import { useContext } from "react";
import Context from "../../../Context";
import InputCard from "../../helperComponents/InputCard";
import PrimaryButton from "../../helperComponents/PrimaryButton";
import goTo from "../../../controllers/goTo";
import BrandContainer from "../../brand/BrandContainer";
import { useTranslation } from "react-i18next";

const Container = styled.div``;
const Main = styled.div``;

export default function LoginWall() {
  return (
    <WithBackground backgroundImage={"/dumbbells.jpg"}>
      <Container>
        <Main>
          <br /> <br /> <br />
          <BrandContainer />
          <br />
          <InputCard title="You can only access this page once you login">
            <PrimaryButton onClick={goTo("/")}>Go Home</PrimaryButton>
          </InputCard>
        </Main>
      </Container>
    </WithBackground>
  );
}
