import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Card = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  cursor: pointer;
  border-radius: 20px;
  background:
    5px 5px 10px rgba(0, 0, 0, 0.3),
    0px 0px 10px #46600c;
  color: var(--accentColor);
  width: 100%;
  font-weight: bold;
  font-size: 20px;
  /* box-shadow: 0 0 10px rgba(255, 255, 255, 0.2); */
  border: 1px solid #ced400;

  ${({ isSelected }) => {
    if (isSelected)
      return `
    box-shadow: rgb(70, 96, 12) 5px 6px 0px;
    background:linear-gradient(90deg, #ced400 0%, #75a500 100%);
    color: var(--bgColor)
    `;
  }}
`;

const CardTitle = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
`;

const PlanName = styled.span`
  font-size: 19px;
`;

const PlanDescription = styled.span`
  font-size: 14px;
  font-weight: 300;
`;

const PriceTag = styled.div`
  background-color: #000;
  padding: 10px 20px;
  border-radius: 20px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;

  background: linear-gradient(90deg, #ced400 0%, #75a500 100%);
  color: var(--bgColor);

  ${({ isSelected }) => {
    if (isSelected)
      return `
    
    background:var(--bgColor);
    color: var(--color)
    `;
  }};
`;

const MembershipSelectionCard = ({
  value,
  onChange,
  price,
  title,
  desc,
  name,
}) => {
  let isSelected = value == name;
  const { t } = useTranslation();
  return (
    <Card
      isSelected={isSelected}
      onClick={() => {
        onChange(name);
      }}
    >
      <CardTitle>
        <PlanName>{title}</PlanName>
        <PlanDescription>{desc}</PlanDescription>
      </CardTitle>
      <PriceTag isSelected={isSelected}>{price}</PriceTag>
    </Card>
  );
};

export default MembershipSelectionCard;
