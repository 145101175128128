import React from "react";
import styled from "styled-components";
import MembershipSelectionCard from "./MembershipSelectionCard";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0;
  margin-bottom: 20px;
  width: 100%;
`;

const Card = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 20px;
  background: ${({ gradient }) => gradient};
  box-shadow: ${({ shadow }) => shadow};
  color: #000;
  font-weight: bold;
  font-size: 20px;
`;

const CardTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

const PlanName = styled.span`
  font-size: 24px;
`;

const PlanDescription = styled.span`
  font-size: 16px;
  color: #a5a5a5;
`;

const PriceTag = styled.div`
  background-color: #000;
  padding: 10px 20px;
  border-radius: 20px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
`;

const SilverGradient = "linear-gradient(90deg, #ced400 0%, #75a500 100%)";
const GoldGradient = "linear-gradient(90deg, #ffcc00 0%, #ff9900 100%)";
const PlatinumGradient =
  "linear-gradient(90deg, #a6ffcb 0%, #12d8fa 51%, #1fa2ff 100%)";

const SilverShadow = "5px 5px 10px rgba(0, 0, 0, 0.3), 0px 0px 10px #46600c";
const CardBorderShadow = "0 0 10px rgba(255, 255, 255, 0.2)";

const MembershipSelection = ({ value, onChange }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <MembershipSelectionCard
        title="Silver"
        desc={"Gym & Locker Room"}
        price="15.000F / Mois"
        value={value}
        onChange={onChange}
        name="SILVER"
      />
      <MembershipSelectionCard
        title="Gold"
        desc={"Silver + Health Checkup"}
        price="25.000F / Mois"
        value={value}
        onChange={onChange}
        name="GOLD"
      />
      <MembershipSelectionCard
        title="Platinum"
        desc={"Gold + Special Events"}
        price="30.000F / Mois"
        value={value}
        onChange={onChange}
        name="PLATINUM"
      />
    </Container>
  );
};

export default MembershipSelection;
