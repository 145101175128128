import BigToggle from "./BigToggle";

export default function OptionMultiPicker({ value, onChange, options, label }) {
  if (!value) value = [];
  return (
    <BigToggle
      title={label ? label : "Select Options"}
      options={options.map((item) => getItem(item))}
    ></BigToggle>
  );

  function getItem(item) {
    return {
      onClick: () => {
        let newItems = [...value];

        let itemID = item.value;

        if (newItems.includes(itemID)) {
          let index = newItems.indexOf(itemID);
          newItems.splice(index, 1);
        } else {
          newItems.push(itemID);
        }

        onChange(newItems);
      },
      isSelected: value.includes(item.value),
      label: item.label,
    };
  }
}
