import { useTranslation } from "react-i18next";
import AdminCardsList from "../AdminCardsList";
import ManageActivityCard from "./ManageActivityCard";

export default function ManageActivitiesPage() {
  const { t } = useTranslation();

  return (
    <AdminCardsList
      title={t("activitiesPageTitle")}
      path={`activities`}
      CardComponent={ManageActivityCard}
      allowCreation={true}
    />
  );
}
