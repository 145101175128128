import LoggedInBoilerplate from "../../LoggedInBoilerplate";
import { useEffect, useState } from "react";
import LoadingSection from "../../../../helperComponents/LoadingSection";
import { serverLine } from "../../../../../controllers/serverLine";
import getUrlQuery from "../../../../../controllers/getUrlQuery";
import ActivityDetails from "./ActivityDetails";
import { useTranslation } from "react-i18next";

export default function ActivityDetailsPage() {
  const [item, setItem] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    let urlQuery = getUrlQuery();
    serverLine.get(`/activity/?itemID=${urlQuery.itemID}`).then(setItem);
  }, []);

  if (!item)
    return (
      <LoggedInBoilerplate>
        <LoadingSection />
      </LoggedInBoilerplate>
    );

  return (
    <LoggedInBoilerplate>
      <ActivityDetails item={item.activity} bookingData={item.booking} />
    </LoggedInBoilerplate>
  );
}
