import styled from "styled-components";

import getImageURL from "../../../../controllers/getImageURL";
import NumberSlider from "../../../helperComponents/NumberSlider";
import { useContext } from "react";
import Context from "../../../../Context";
import { useTranslation } from "react-i18next";

const Image = styled.img`
  height: 130px;
  width: 130px;
  border-radius: 10px;
  object-fit: cover;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  gap: 30px;
  background-color: var(--translucentLight);
  border-radius: 10px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Title = styled.div`
  font-size: 25px;
  font-weight: 900;
`;

const Row2 = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 700;
  gap: 20px;
  align-items: center;
`;

const Quantity = styled.div`
  font-weight: 300;
`;

const Total = styled.div``;

export default function CartItem({ listingData, quantity, price }) {
  const { loggedInUser, setLoggedInUser } = useContext(Context);
  const { t } = useTranslation();

  if (!listingData.images) listingData.images = [];

  return (
    <Row>
      <Image src={getImageURL(listingData.images[0])} />
      <Info>
        <Title>{listingData.title}</Title>
        <Row2>
          <NumberSlider value={quantity} onChange={updateQuantity} />
          <Quantity>
            {quantity} X {price} = {quantity * price}
          </Quantity>
        </Row2>
      </Info>
    </Row>
  );

  function updateQuantity(newValue) {
    if (newValue > listingData.currentStock)
      return window.popupAlert("That many stocks aren't available");

    let newLoggedInUser = { ...loggedInUser };

    let cart = newLoggedInUser.cart ? newLoggedInUser.cart : {};

    cart[listingData._id].quantity = newValue;

    if (!newValue) delete cart[listingData._id];

    setLoggedInUser(newLoggedInUser);
  }
}
