import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import MembershipPlans from "../../loggedOut/landingPage/MembershipPlans";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 30px;
  /* border-top: 1px solid rgba(255, 255, 255, 0.1); */
  padding: 100px 0;
  padding-top: 0;
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.1); */
`;

const Title = styled.h1``;

const LoggedInMembershipSection = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Title>Membership Plans</Title>

      <MembershipPlans />
    </Container>
  );
};

export default LoggedInMembershipSection;
