import styled from "styled-components";
import { useScroll, motion } from "framer-motion";
import { useRef } from "react";
import LoggedOutSectionTitle from "./LoggedOutSectionTitle";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 150px;
  align-items: space-between;
  margin-top: 30px;
  padding-bottom: 80px;
  margin-bottom: 100px;
  width: 80vw;

  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 50px;
  }
`;

const Img = styled.img`
  width: 300px;
  height: auto;
  object-fit: contain;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: flex-start;
  margin-top: 100px;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;

  gap: 10px;
`;

const Description = styled.div`
  /* text-align: center; */
  opacity: 0.7;
`;

export default function AboutUs({ containerRef }) {
  const targetRef = useRef(null);

  const { t } = useTranslation();

  const animation = useScroll({
    container: containerRef,
    target: targetRef,
    offset: ["0 1", "0.7 1"],
  });

  return (
    <Container ref={targetRef} id="about-us">
      <Img src="/hero/steelbody.png" />
      <Main>
        <motion.div
          style={{
            scale: animation.scrollYProgress,
            display: "flex",
            flexDirection: "column",
            gap: "50px",
          }}
        >
          <Top>
            <LoggedOutSectionTitle>
              {t("aboutUsSectionTitle")}
            </LoggedOutSectionTitle>
            <Description>
              {t("aboutUsSectionDesc")}

              <br />
              <br />
              {t("aboutUsSectionDesc2")}

              <br />
              <br />
              {t("aboutUsSectionDesc3")}
            </Description>
          </Top>
        </motion.div>
      </Main>
    </Container>
  );
}
