import React from "react";
import styled from "styled-components";
import formatDate from "../../../../controllers/formatDate";
import SemanticButton from "../../../helperComponents/SemanticButton";
import { useTranslation } from "react-i18next";
import ExactSlotTime from "./scheduleCheckup/ExactSlotTime";

const Card = styled.div`
  background: #111111;
  border-radius: 15px;
  width: 350px;
  overflow: hidden;
  color: white;
  position: relative;
  transition: all 0.25s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(0.95);
  }

  border: 1px solid rgba(255, 255, 255, 0.1);

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const InfoContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Title = styled.h2`
  font-size: 18px;
  font-weight: bold;
  color: #c9d400;
  margin: 0;
`;

const Subtitle = styled.div`
  font-size: 16px;
  color: #808080;
`;

const InfoData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export default function HealthReportCard({ item }) {
  const { t } = useTranslation();

  return (
    <SemanticButton href={`/health-report/?itemID=${item._id}`}>
      <Card>
        <InfoContainer>
          <Title> {formatDate(item.date)} </Title>
          <InfoData>
            <Subtitle>
              {t("bookHealthCheckupTime")}:{" "}
              <ExactSlotTime slot={item.slot} hour={item.hour} />
            </Subtitle>
            <Subtitle>
              {t("bookHealthCheckupStatus")}: {item.status.toLowerCase()}
            </Subtitle>
            <Subtitle>
              {t("bookHealthCheckupReport")}:{" "}
              {item.reportFile ? "Available" : "Pending"}
            </Subtitle>
          </InfoData>
        </InfoContainer>
      </Card>
    </SemanticButton>
  );
}
