import styled from "styled-components";
import BrandContainer from "../../../brand/BrandContainer.js";
import CustomButton from "../../../helperComponents/CustomButton.js";
import scrollToSection from "../../../../controllers/scrollToSection.js";
import { useContext } from "react";
import Context from "../../../../Context.js";
import { useTranslation } from "react-i18next";
import goTo from "../../../../controllers/goTo.js";

let linkVariant = "minimal";

const TopPart = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 90px;
  width: 90vw;

  @media (max-width: 900px) {
    justify-content: center;
    margin-top: 30px;
    flex-direction: column;
  }
`;

const Links = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
  padding: 0px 20px;
  border-radius: 10px;
  padding-left: 40px;
  height: 70px;
  /* background: var(--mainGradient); */

  @media (max-width: 900px) {
    /* display: none; */
    justify-content: center;
    flex-wrap: wrap;
    gap: 0;
    display: none;
  }
`;

const AnimatedHeroImage = styled.img`
  width: 400px;
  margin-right: 100px;
  transform: scaleX(-1);
  /* margin-top: -50px; */
`;

const mainButtonStyle = {
  background: "var(--mainGradient)",
  color: "#111",
  boxShadow: "rgb(0 0 0 / 14%) red",
};

const btnStyle = {
  color: "var(--accentColor)",
};

const btnStyle2 = {
  height: "40px",
  background: "var(--accentColor)",
  color: "#111",
};

export default function LoggedOutHeader() {
  const { isMobile } = useContext(Context);

  const { t } = useTranslation();

  return (
    <TopPart>
      <BrandContainer />
      <Links>
        <CustomButton
          onClick={goTo("/buy-activity")}
          variant={linkVariant}
          style={btnStyle}
        >
          {t("bookActivity")}
        </CustomButton>
        <CustomButton
          onClick={scrollToSection("our-track-record")}
          variant={linkVariant}
          style={btnStyle}
        >
          {t("ourTrackRecord")}
        </CustomButton>
        <CustomButton
          onClick={goTo("/buy-membership")}
          variant={linkVariant}
          style={btnStyle}
        >
          {t("membership")}
        </CustomButton>
        <CustomButton
          onClick={scrollToSection("about-us")}
          variant={linkVariant}
          style={btnStyle}
        >
          {t("aboutUs")}
        </CustomButton>
        <CustomButton
          onClick={scrollToSection("contact-us")}
          variant={linkVariant}
          style={btnStyle}
        >
          {t("contactUs")}
        </CustomButton>
        <CustomButton variant="filled" href="/auth" style={btnStyle2}>
          {t("loginOrSignup")}
        </CustomButton>
      </Links>
    </TopPart>
  );
}
