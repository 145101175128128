import styled from "styled-components";
import CustomButton from "../../../helperComponents/CustomButton";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  padding: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #111;
  align-items: center;
  margin-top: 100px;
  /* position: ; */
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  @media (max-width: 900px) {
    font-size: 12px;
  }
`;

const Links = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const RowTitle = styled.div`
  font-size: 17px;
  display: none;
  font-weight: 900;
`;

let variant = "minimal-underlined";

export default function Footer() {
  const [lang, setLang] = useState(localStorage.getItem("langPreference"));

  const { t } = useTranslation();
  let theLang = lang ? lang : "fr";

  console.log(theLang);

  let date = new Date();
  return (
    <Container>
      <Row>
        {/* <RowTitle>Useful Links</RowTitle> */}
        <Links>
          <CustomButton variant={variant} onClick={changeLanguage}>
            {theLang == "fr" ? "Switch to English" : "Passer au français"}{" "}
          </CustomButton>

          <CustomButton variant={variant} href="/privacy-policy">
            {t("footerSectionPrivacyPolicy")}
          </CustomButton>
          <CustomButton variant={variant} href="/terms-and-conditions">
            {t("footerSectionTermsAndConditions")}
          </CustomButton>
          <CustomButton variant={variant} href="/refund-policy">
            {t("footerSectionRefundPolicy")}
          </CustomButton>
        </Links>
      </Row>

      <Row>Copyright © {date.getFullYear()} Wellness Care Center</Row>

      <br />
      <br />
      <br />
    </Container>
  );

  function changeLanguage() {
    localStorage.setItem("langPreference", theLang == "fr" ? "en" : "fr");
    window.location.reload();
  }
}
