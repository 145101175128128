import styled from "styled-components";
import LoggedInHeader from "./LoggedInHeader";
import WithBackground from "../../core/boilerplate/WithBackground";
import Footer from "../loggedOut/landingPage/Footer";
import { useContext } from "react";
import Context from "../../../Context";
import InputCard from "../../helperComponents/InputCard";
import PrimaryButton from "../../helperComponents/PrimaryButton";
import goTo from "../../../controllers/goTo";
import BrandContainer from "../../brand/BrandContainer";
import { useTranslation } from "react-i18next";
import LoginWall from "./LoginWall";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  width: 90vw;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100dvh;
  overflow-y: scroll;
  align-items: center;
`;

export default function LoggedInBoilerplate({ children, showBackgroundImage }) {
  const { loggedInUserID } = useContext(Context);
  const { t } = useTranslation();

  if (!loggedInUserID) return <LoginWall />;

  return (
    <WithBackground
      backgroundImage={showBackgroundImage ? "/dumbbells.jpg" : null}
    >
      <Main>
        <Container>
          <LoggedInHeader />
          {children}

          <br />
          <br />
          <br />
          <br />
        </Container>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        {showBackgroundImage ? null : <Footer />}
      </Main>
    </WithBackground>
  );
}
