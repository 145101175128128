import { useScroll, useSpring, motion } from "framer-motion";
import React, { useContext, useRef } from "react";
import styled from "styled-components";
import LoggedOutSectionTitle from "./LoggedOutSectionTitle";
import Context from "../../../../Context";
import { useTranslation } from "react-i18next";
import MembershipPlans from "./MembershipPlans";

const PricingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #000;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const PlanCard = styled.div`
  background: ${(props) =>
    props.mostPopular
      ? "linear-gradient(90deg, #ced400 0%, #75a500 100%)"
      : "transparent"};
  color: ${(props) => (props.mostPopular ? "#000" : "#ced400")};
  border-radius: 10px;
  padding: 20px 30px;
  margin: 0 10px;
  box-shadow: ${(props) =>
    props.mostPopular ? "0 0 10px rgba(0, 0, 0, 0.2)" : "none"};
  min-width: 350px;
  position: relative;
  border: 1px solid #ced400;

  &:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  }
`;

const PlanPrice = styled.h2`
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: 900;
`;

const PlanName = styled.h3`
  font-size: 23px;
  margin-bottom: 10px;
`;

const PlanDescription = styled.p`
  font-size: 16px;
  margin-bottom: 40px;
`;

const PlanFeatures = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0 0 20px;
  font-size: 16px;
  margin-bottom: 30px;
`;

const PlanFeature = styled.li`
  margin-bottom: 15px;
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */

  &::before {
    background-color: rgba(0, 0, 0, 0.5);
    height: 20px;
    width: 20px;
    content: "✓";
    color: #ced400;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }
`;

const ChooseButton = styled.button`
  background: ${(props) =>
    props.mostPopular ? "var(--bgColor)" : "var(--mainGradient)"};
  color: ${(props) => (props.mostPopular ? "#ced400" : "#000")};
  border: none;
  border-radius: 10px;
  padding: 15px 20px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;

  transition: all 0.25s ease-in-out;
  &:hover {
    transform: scale(0.9);
  }
`;

const MostPopularBadge = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: #000;
  color: #ced400;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 5px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 100px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 30px;
`;

const Title = styled.h1``;

const LoggedOutMembershipSection = ({ containerRef }) => {
  const { isMobile } = useContext(Context);
  const { t } = useTranslation();
  const targetRef = useRef(null);

  const animation = useScroll({
    container: containerRef,
    target: targetRef,
    offset: ["0 1", "0.7 1"],
  });

  const animationMobile = useScroll({
    container: containerRef,
    target: targetRef,
    offset: ["0 1", "0.5 1"],
  });

  return (
    <Container ref={targetRef} id="membership">
      <motion.div
        style={{
          scale: isMobile ? 1 : animation.scrollYProgress,
        }}
      >
        <Main>
          <LoggedOutSectionTitle>
            {t("membershipPlanTitle")}
          </LoggedOutSectionTitle>

          <MembershipPlans />
        </Main>
      </motion.div>
    </Container>
  );
};

export default LoggedOutMembershipSection;
