import { useState } from "react";
import ListPage from "../ListPage";
import StoreItemCard from "./StoreItemCard";
import styled from "styled-components";
import MaterialInput from "../../../helperComponents/MaterialInput";
import extractEventValue from "../../../../controllers/utils/extractEventValue";
import DropDownCategories from "../activity/upcomingActivities/DropDownCategories";
import CustomButton from "../../../helperComponents/CustomButton";
import { useTranslation } from "react-i18next";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  @media (max-width: 900px) {
    flex-direction: column;
    width: 100%;
    width: 90vw;
  }
`;

const Second = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export default function ShopPage() {
  const [category, setCategory] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const { t } = useTranslation();

  let filterComponent = (
    <Row>
      <MaterialInput
        style={{ background: "var(--translucent)" }}
        label={t("shoppingPageSearchInputLabel")}
        placeholder={t("shoppingPageSearchInputPlaceholder")}
        value={searchQuery}
        onChange={extractEventValue(setSearchQuery)}
      />

      <Second>
        <DropDownCategories value={category} onChange={setCategory} />
        <CustomButton
          onClick={() => {
            setCategory(null);
            setSearchQuery(null);
          }}
        >
          {t("shoppingPageReset")}
        </CustomButton>
      </Second>
    </Row>
  );

  return (
    <ListPage
      filterComponent={filterComponent}
      path="/store-items"
      title={t("shoppingPageTitle")}
      CardComponent={StoreItemCard}
      filterFunction={filterFunction}
    />
  );

  function filterFunction(dataList) {
    // return dataList;
    if (!searchQuery && !category) {
      return dataList;
    }

    return dataList.filter((item) => {
      // return true;
      let totalString = JSON.stringify(item);
      totalString = totalString.toLowerCase();
      let theQuery = searchQuery ? searchQuery.toLowerCase() : null;

      if (theQuery) {
        if (totalString.indexOf(theQuery) == -1) {
          return false;
        }
      }

      if (category) {
        if (item.category !== category) return false;
      }

      return true;
    });
  }
}
