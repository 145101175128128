import { useEffect, useState } from "react";

import LoadingSection from "../../../helperComponents/LoadingSection";
import { serverLine } from "../../../../controllers/serverLine";
import BigToggle from "../../../helperComponents/BigToggle";
import { useTranslation } from "react-i18next";

export default function ActivityTypePicker({ value, onChange, label }) {
  const [items, setItems] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    serverLine.get("/admin/activity-types").then(setItems);
  }, []);

  if (!items) return <LoadingSection />;

  return (
    <BigToggle
      title={label}
      options={items.map((item) => getItem(item))}
    ></BigToggle>
  );

  function getItem(item) {
    return {
      onClick: () => {
        onChange(item._id);
      },
      isSelected: value === item._id,
      label: `${item.title}`,
    };
  }
}
