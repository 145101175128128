import { TextField } from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers";

export default function MaterialInput({
  value,
  onChange,
  label,
  disableUnderline,
  fontSize,
  multiline,
  maxRows,
  onEnter,
  fontWeight,
  rows,
  type,
  autoFocus = false,
  onlyYear,
  variant = "filled",
  placeholder,
  style = {},
}) {
  if (type == "dateAndTime")
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          label={label}
          views={onlyYear ? ["year"] : null}
          onChange={(newDate) => {
            let theVal = newDate["$d"];

            console.log(newDate["$d"].toString());
            if (theVal.toString() === "Invalid Date") return true;

            console.log(newDate["$d"].toISOString());
            onChange({ target: { value: newDate["$d"].toISOString() } });
          }}
          value={value ? dayjs(value) : null}
          sx={{
            input: { color: "var(--color)", padding: "19px 17px !important" },
            root: { color: "var(--color)" },
          }}
          InputProps={{
            disableUnderline: disableUnderline ? true : false,
            style: {
              overflow: "hidden",
              borderRadius: "10px",
              color: "var(--color)",
              ...style,
            },
          }}
        />
      </LocalizationProvider>
    );

  if (type == "date")
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label={label}
          views={onlyYear ? ["year"] : null}
          onChange={(newDate) => {
            let theVal = newDate["$d"];

            console.log(newDate["$d"].toString());
            if (theVal.toString() === "Invalid Date") return true;

            console.log(newDate["$d"].toISOString());
            onChange({ target: { value: newDate["$d"].toISOString() } });
          }}
          value={value ? dayjs(value) : null}
          sx={{
            input: { color: "var(--color)", padding: "19px 17px !important" },
            root: { color: "var(--color)" },
          }}
          InputProps={{
            disableUnderline: disableUnderline ? true : false,
            style: {
              overflow: "hidden",
              borderRadius: "10px",
              color: "var(--color)",
            },
          }}
        />
      </LocalizationProvider>
    );

  return (
    <TextField
      autoFocus={autoFocus}
      type={type}
      InputLabelProps={{ style: { fontSize: fontSize, color: "var(--color)" } }}
      InputProps={{
        disableUnderline: disableUnderline ? true : false,

        style: {
          fontSize: fontSize,
          fontWeight: fontWeight,
          borderRadius: "10px",
          color: "var(--color)",
          backdropFilter: "blur(20px)",
          background: "var(--translucent)",
          border: "1px solid var(--glassBorder)",
          boxShadow: "var(--lightShadow)",
          ...style,
        },
      }}
      sx={{ input: { color: "var(--color)" } }}
      fullWidth
      multiline={multiline}
      maxRows={maxRows}
      placeholder={placeholder}
      onKeyDown={(ev) => {
        console.log(`Pressed keyCode ${ev.key}`);
        if (ev.key === "Enter") {
          if (onEnter) {
            onEnter(ev);
          }
          // Do code here
          ev.preventDefault();
        }
      }}
      rows={rows}
      value={value}
      onChange={onChange}
      label={label}
      variant={variant}
    />
  );
}
