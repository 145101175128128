import { useTranslation } from "react-i18next";
import capitalizeFirstLetter from "../../../../../controllers/capitalizeFirstLetter";
import BigToggle from "../../../../helperComponents/BigToggle";
import daysArray from "../../../../../data/daysArray";

export default function ScheduleDayPicker({ value, onChange, label }) {
  let items = daysArray;

  const { t } = useTranslation();

  if (!value) value = [];

  return (
    <BigToggle
      title={label}
      options={items.map((item) => getItem(item))}
    ></BigToggle>
  );

  function getItem(item) {
    return {
      onClick: () => {
        let newItems = [...value];

        if (newItems.includes(item)) {
          let index = newItems.indexOf(item);
          newItems.splice(index, 1);
        } else {
          newItems.push(item);
        }

        onChange(newItems);
      },
      isSelected: value.includes(item),
      label: capitalizeFirstLetter(item.toLowerCase()),
    };
  }
}
