import { useContext, useEffect, useState } from "react";
import BigToggle from "../../../../helperComponents/BigToggle";
import Context from "../../../../../Context";
import LoadingSection from "../../../../helperComponents/LoadingSection";
import { serverLine } from "../../../../../controllers/serverLine";
import { useTranslation } from "react-i18next";
import addAdditionalZero from "../../../../../controllers/addAdditionalZero";

export default function ScheduleSlotPicker({
  value,
  onChange,
  date,
  hour,
  label,
}) {
  const { adminSettings } = useContext(Context);
  const { t } = useTranslation();
  const [healthCheckupBookings, setHealthCheckupBooking] = useState(null);

  useEffect(() => {
    serverLine
      .get("/all-upcoming-health-checkups")
      .then(setHealthCheckupBooking);
  }, []);

  useEffect(() => {
    onChange(null);
  }, [date, hour]);

  if (!healthCheckupBookings) return <LoadingSection />;

  let existingBookingMap = {};

  for (let item of healthCheckupBookings) {
    let id = generateID(item);
    existingBookingMap[id] = true;
  }

  // console.log(existingBookingMap);
  if (!hour || !date) return null;

  let items = getSlots(getNumberOfSlots());

  return (
    <BigToggle
      title={label}
      options={items.map((item) => getItem(item))}
    ></BigToggle>
  );

  function getItem(item) {
    let booked = isBooked(item);

    if (value === item && booked) {
      booked = false;
    }

    // let booked = false;

    // console.log(item, value, item == value);

    let oneSlotVal = getValueOfOneSlotInMins();

    return {
      onClick: booked
        ? null
        : () => {
            onChange(item);
          },
      isSelected: value === item,
      label: booked
        ? "BOOKED"
        : `${addAdditionalZero(hour)}:${addAdditionalZero(oneSlotVal * (item - 1))}`,
    };
  }

  function getValueOfOneSlotInMins() {
    let totalSlots = getNumberOfSlots();

    return Math.round(60 / totalSlots);
  }

  function isBooked(slotNumber) {
    let thisID = generateID({ hour, date, slot: slotNumber });

    console.log(slotNumber, thisID, existingBookingMap[thisID]);
    if (existingBookingMap[thisID]) {
      return true;
    }

    return false;
  }

  function generateID({ hour, date, slot }) {
    return `${date}-${hour}-${slot}`;
  }

  function getNumberOfSlots() {
    let slots = 4;

    if (adminSettings) {
      let data = adminSettings.data;

      if (data.healthCheckupSlotsPerHour) {
        slots = data.healthCheckupSlotsPerHour;
      }
    }

    return slots;
  }
}

function getSlots(max) {
  let timeSlots = [];

  for (let i = 1; i <= max; i++) {
    timeSlots.push(i);
  }

  return timeSlots;
}
