import { styled } from "styled-components";

import { useEffect, useState } from "react";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { nanoid } from "nanoid";
import CategoryItemEditor from "./CategoryItemEditor";

import extractEventValue from "../../../../controllers/utils/extractEventValue";
import MaterialInput from "../../../helperComponents/MaterialInput";
import CustomButton from "../../../helperComponents/CustomButton";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  /* background: var(--translucent); */
  /* padding: 25px; */
  border-radius: 15px;
  flex-direction: column;
  gap: 50px;
  width: 100%;
  position: relative;
  flex: 1;
  font-size: 21px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const SectionTitle = styled.h1``;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export default function CategoryEditor({ value, onChange }) {
  const [temporaryCategoryName, setTemporaryCategoryName] = useState("");
  const { t } = useTranslation();

  if (!value) value = [];

  return (
    <Container>
      <Section>
        <SectionTitle> {t("adminPageStoreAddCategory")} </SectionTitle>
        <Row>
          <MaterialInput
            label={"Type new category name here"}
            placeholder={"Category name goes here"}
            onChange={extractEventValue(setTemporaryCategoryName)}
          />
          <CustomButton onClick={addItem}>
            {t("adminPageStoreEditAddItem")}
          </CustomButton>
        </Row>
      </Section>

      <Section>
        <SectionTitle>{t("adminPageStoreEditCategory")}</SectionTitle>
        <List>{renderItems()}</List>
      </Section>
    </Container>
  );

  function moveUp(sourceIndex) {
    let newIndex = sourceIndex - 1;
    if (newIndex < 0) return;
    handleOnDragEnd({
      source: { index: sourceIndex },
      destination: { index: newIndex },
    });
  }

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    let newValues = [...value];

    const items = Array.from(newValues);
    const [reorderedItem] = items.splice(result.source.index, 1);

    items.splice(result.destination.index, 0, reorderedItem);

    onChange(items);
  }

  function renderItems() {
    if (!value.length) return null;

    let theList = value;

    let toRender = theList.map((item, index) => (
      <CategoryItemEditor
        moveUp={moveUp}
        index={index}
        onChange={updateItem}
        item={item}
        onRemove={removeItem}
      />
    ));

    return toRender;
  }

  function updateItem(newItem, index) {
    console.log(newItem, index);
    let newValues = [...value];
    newValues[index] = newItem;

    onChange(newValues);
  }

  function removeItem(_id) {
    let newList = [];

    for (let item of value) {
      if (item._id !== _id) {
        newList.push(item);
      }
    }

    onChange(newList);
  }

  function addItem() {
    let itemID = nanoid();
    let newValue = { _id: itemID, name: temporaryCategoryName };

    let list = [newValue, ...value];
    onChange(list);
  }
}
