import React from "react";
import styled from "styled-components";
import Link from "../../helperComponents/Link";
import { useTranslation } from "react-i18next";

const Card = styled.div`
  padding: 0 20px;
  border-radius: 10px;
  width: auto;
  color: white;
  height: 65px;
  background: linear-gradient(45deg, #d4cfcf1c, #000000, #c8c8c824);
  border: 1px solid #d1da303b;
  gap: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */

  cursor: pointer;

  transition: all 0.25s ease-in-out;

  &:hover {
    transform: scale(0.9);
  }

  @media (max-width: 900px) {
    height: 50px;
    padding: 0 10px;
  }
`;

const Title = styled.h2`
  font-size: 15px;
  font-weight: 600;
  color: #c9d400;
  margin: 0;

  @media (max-width: 900px) {
    font-size: 10px;
  }
`;

const Expiration = styled.p`
  font-size: 12px;
  color: #808080;
  margin: 0;

  @media (max-width: 900px) {
    font-size: 10px;
  }
`;

const Highlight = styled.span`
  color: #c9d400;
  font-weight: bold;
  margin: 0;
`;

const MembershipMiniInfo = () => {
  const { t } = useTranslation();

  return (
    <Link href="/membership">
      <Card>
        <Title>
          {t("subscriptionMiniInfoTitle")}: <Highlight>Premium</Highlight>
        </Title>
        <Expiration>
          {t("subscriptionMiniInfoExpiration")}: <Highlight>12/09/25</Highlight>
        </Expiration>
      </Card>
    </Link>
  );
};

export default MembershipMiniInfo;
