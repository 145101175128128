import styled from "styled-components";
import WithBackground from "../../core/boilerplate/WithBackground";
import LoggedInHeader from "../loggedIn/LoggedInHeader";
import LoggedInMembershipSection from "../loggedIn/membership/LoggedInMembershipSection";
import LoggedInBoilerplate from "../loggedIn/LoggedInBoilerplate";
import { useTranslation } from "react-i18next";
import LoggedOutHeader from "./landingPage/LoggedOutHeader";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  align-items: center;
  gap: 50px;
`;

const Title = styled.h2``;

export default function LoggedOutBoilerplate({
  children,
  showBackgroundImage = true,
}) {
  return (
    <WithBackground
      backgroundImage={showBackgroundImage ? "/dumbbells.jpg" : null}
    >
      <Container>
        <LoggedOutHeader />

        {children}
      </Container>
    </WithBackground>
  );
}
