import styled from "styled-components";
import WhyChooseUsCard from "./WhyChooseUsCard";
import { useScroll, motion } from "framer-motion";
import { useRef } from "react";
import FeatureRowItem from "./FeatureRowItem";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  /* gap: 60px; */
  justify-content: space-between;
  margin-top: 100px;
  width: 90vw;

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    gap: 23px;
  }
`;

export default function FeatureRow({ containerRef }) {
  const targetRef = useRef(null);
  const { t } = useTranslation();

  return (
    <Container>
      <FeatureRowItem image="biceps.png" title={t("eliteTrainer")} />

      <FeatureRowItem image="heart.png" title={t("regularHealthCheckups")} />

      <FeatureRowItem image="dumbbell.png" title={t("cuttingEdgeEquipments")} />

      <FeatureRowItem image="events.png" title={t("exclusiveEvents")} />
    </Container>
  );
}
