import styled from "styled-components";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import AliceCarousel, { Link } from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import { useTranslation } from "react-i18next";
import getImageURL from "../../../../controllers/getImageURL";
import ReactImageZoom from "react-image-zoom";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import AliceCarouselLink from "../../../helperComponents/AliceCarouselLink";
import StoreItemImageGalleryItem from "./StoreItemImageGalleryItem";

const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TopLeft = styled.div`
  font-size: 27px;
  color: var(--accentColor);
`;

const TopRight = styled.div``;

const List = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 560px;
  height: 420px;
  /* overflow: hidden; */
  padding: 30px 0;
  padding-top: 0;
  padding-bottom: 10px;
  border-radius: 16px;

  background-color: var(--translucent);
  border: 1px solid #d1da303b;

  @media (max-width: 900px) {
    width: 90vw;
    height: 350px;
  }
`;

const Image = styled.img`
  width: 560px;
  height: 350px;
  /* margin-top: -10px; */
  object-fit: cover;
  /* pointer-events: none; */
`;

export default function StoreItemImageGallery({ images }) {
  const { t } = useTranslation();

  if (!images) images = [];

  let allItems = images.map((item) => (
    <StoreItemImageGalleryItem item={item} />
  ));

  return (
    <Container>
      <AliceCarousel
        disableButtonsControls={true}
        autoPlayInterval={3000}
        // disableDotsControls={true}
        infinite={true}
        autoPlay={true}
        mouseTracking={true}
        touchTracking={true}
      >
        {allItems}
      </AliceCarousel>
    </Container>
  );
}
