import styled from "styled-components";
import LoggedInBoilerplate from "../LoggedInBoilerplate";
import getUrlQuery from "../../../../controllers/getUrlQuery";
import { useEffect, useState } from "react";
import { serverLine } from "../../../../controllers/serverLine";
import LoadingSection from "../../../helperComponents/LoadingSection";
import getImageURL from "../../../../controllers/getImageURL";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 40px 0;
  background-color: #000;
  color: #fff;
  width: 100%;

  @media (max-width: 900px) {
    flex-direction: column-reverse;
    justify-content: flex-start;
    gap: 40px;
  }
`;

const InfoSection = styled.div`
  max-width: 600px;

  @media (max-width: 900px) {
    max-width: 80vw;
  }
`;

const Title = styled.h1`
  font-size: 36px;
  color: #c9d400;
`;

const Description = styled.div`
  margin: 20px 0;
`;

const Subtitle = styled.h2`
  font-size: 24px;
  /* color: #c9d400; */
  margin-bottom: 10px;
  opacity: 0.6;
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 1.5;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
`;

const Image = styled.img`
  width: 400px;
  border-radius: 0;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const ImageSection = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--translucent);
  border: var(--highlightBorder);
  border-radius: 10px;
  overflow: hidden;
`;

export default function AnnouncementPage() {
  const [item, setItem] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    let urlQuery = getUrlQuery();
    serverLine.get(`/announcement/?itemID=${urlQuery.itemID}`).then(setItem);
  }, []);

  if (!item)
    return (
      <LoggedInBoilerplate>
        <LoadingSection />
      </LoggedInBoilerplate>
    );

  return (
    <LoggedInBoilerplate>
      <Container>
        <InfoSection>
          <Title>{item.title}</Title>
          <List>{item.description}</List>
        </InfoSection>

        <RightSection>
          <ImageSection>
            <Image src={getImageURL(item.image)} />
          </ImageSection>
        </RightSection>
      </Container>
    </LoggedInBoilerplate>
  );
}
