import styled from "styled-components";
import PrimaryButton from "./PrimaryButton";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding: 30px;
  width: 430px;
  height: auto;
  border-radius: 10px;
  box-shadow: 1px 1px 20px 20px #d1da3012;
  border: 1px solid var(--accentBorder);
  background: var(--translucentLight);
  padding-bottom: 50px;

  @media (max-width: 900px) {
    width: 90vw !important;
  }
`;

const Title = styled.div`
  font-weight: 900;
  text-align: center;
  font-size: 23px;
  color: var(--accentColor);
  text-transform: capitalize;
`;

const Medium = styled.div`
  /* font-weight: 900; */
  opacity: 0.4;
  font-size: 15px;
  text-align: center;
  color: var(--accentColor);
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
  align-items: center;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 20px;
  margin-bottom: 20px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 20px;
`;

export default function InputCard({
  title,
  desc,
  children,
  onSubmit,
  submitButtonText,
  style,
  textContainerStyle,
  additionalButtons,
}) {
  return (
    <Container style={style}>
      <Top style={textContainerStyle}>
        <Title>{title}</Title>
        {desc ? <Medium>{desc}</Medium> : null}
      </Top>
      <Inputs>{children}</Inputs>
      <Buttons>
        {additionalButtons}
        {onSubmit ? (
          <PrimaryButton onClick={onSubmit}>{submitButtonText}</PrimaryButton>
        ) : null}
      </Buttons>
    </Container>
  );
}
