function getServerURL() {
  let base = "https://wellness-backend.upon.one";

  // base = "https://eden-wellness-care-center-8r7z.vercel.app"

  if (window.location.origin.indexOf("localhost") !== -1) {
    if (process.env.REACT_APP_SERVER) base = process.env.REACT_APP_SERVER;
  }

  // console.log(base);
  return base;
}

export default getServerURL;
