import HealthReportCard from "../HealthReportCard";

import ListPage from "../../ListPage";
import { useTranslation } from "react-i18next";

export default function HealthReportsPage() {
  const { t } = useTranslation();

  return (
    <ListPage
      CardComponent={HealthReportCard}
      path="/my-health-checkup-bookings"
      title={t("healthCheckupReportTitle")}
    />
  );
}
