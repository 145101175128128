import React, { useContext, useRef } from "react";
import styled from "styled-components";
import Context from "../../../../Context";
import { useTranslation } from "react-i18next";

const PricingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #000;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const PlanCard = styled.div`
  background: ${(props) =>
    props.mostPopular
      ? "linear-gradient(90deg, #ced400 0%, #75a500 100%)"
      : "transparent"};
  color: ${(props) => (props.mostPopular ? "#000" : "#ced400")};
  border-radius: 10px;
  padding: 20px 30px;
  margin: 0 10px;
  box-shadow: ${(props) =>
    props.mostPopular ? "0 0 10px rgba(0, 0, 0, 0.2)" : "none"};
  min-width: 350px;
  position: relative;
  border: 1px solid #ced400;

  &:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  }
`;

const PlanPrice = styled.h2`
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: 900;
`;

const PlanName = styled.h3`
  font-size: 23px;
  margin-bottom: 10px;
`;

const PlanDescription = styled.p`
  font-size: 16px;
  margin-bottom: 40px;
`;

const PlanFeatures = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0 0 20px;
  font-size: 16px;
  margin-bottom: 30px;
`;

const PlanFeature = styled.li`
  margin-bottom: 15px;
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */

  &::before {
    background-color: rgba(0, 0, 0, 0.5);
    height: 20px;
    width: 20px;
    content: "✓";
    color: #ced400;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }
`;

const ChooseButton = styled.button`
  background: ${(props) =>
    props.mostPopular ? "var(--bgColor)" : "var(--mainGradient)"};
  color: ${(props) => (props.mostPopular ? "#ced400" : "#000")};
  border: none;
  border-radius: 10px;
  padding: 15px 20px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;

  transition: all 0.25s ease-in-out;
  &:hover {
    transform: scale(0.9);
  }
`;

const MostPopularBadge = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: #000;
  color: #ced400;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 5px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 100px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 30px;
`;

const MembershipPlans = ({ containerRef }) => {
  const { isMobile } = useContext(Context);
  const { t } = useTranslation();

  return (
    <Main>
      <PricingContainer>
        <PlanCard mostPopular>
          <MostPopularBadge> {t("mostPopular")} </MostPopularBadge>
          <PlanPrice> {t("membershipPlanOnePrice")} </PlanPrice>
          <PlanName>{t("membershipPlanOneName")}</PlanName>
          <PlanDescription>{t("membershipPlanOneDesc")}</PlanDescription>
          <PlanFeatures>
            <PlanFeature>{t("membershipPlanOneBullet1")}</PlanFeature>
            <PlanFeature>{t("membershipPlanOneBullet2")}</PlanFeature>
            <PlanFeature>{t("membershipPlanOneBullet3")}</PlanFeature>
            <PlanFeature>{t("membershipPlanOneBullet4")}</PlanFeature>
            <PlanFeature>{t("membershipPlanOneBullet5")}</PlanFeature>
          </PlanFeatures>
          <ChooseButton mostPopular>{t("choosePlan")}</ChooseButton>
        </PlanCard>

        <PlanCard>
          <PlanPrice> {t("membershipPlanTwoPrice")} </PlanPrice>
          <PlanName>{t("membershipPlanTwoName")}</PlanName>
          <PlanDescription>{t("membershipPlanTwoDesc")}</PlanDescription>
          <PlanFeatures>
            <PlanFeature>{t("membershipPlanTwoBullet1")}</PlanFeature>
            <PlanFeature>{t("membershipPlanTwoBullet2")}</PlanFeature>
            <PlanFeature>{t("membershipPlanTwoBullet3")}</PlanFeature>
            <PlanFeature>{t("membershipPlanTwoBullet4")}</PlanFeature>
          </PlanFeatures>
          <ChooseButton>{t("choosePlan")}</ChooseButton>
        </PlanCard>

        <PlanCard>
          <PlanPrice> {t("membershipPlanThreePrice")} </PlanPrice>
          <PlanName>{t("membershipPlanThreeName")}</PlanName>
          <PlanDescription>{t("membershipPlanThreeDesc")}</PlanDescription>
          <PlanFeatures>
            <PlanFeature>{t("membershipPlanThreeBullet1")}</PlanFeature>
            <PlanFeature>{t("membershipPlanThreeBullet2")}</PlanFeature>
            <PlanFeature>{t("membershipPlanThreeBullet3")}</PlanFeature>
          </PlanFeatures>
          <ChooseButton>{t("choosePlan")}</ChooseButton>
        </PlanCard>
      </PricingContainer>
    </Main>
  );
};

export default MembershipPlans;
