import React, { useContext } from "react";
import styled from "styled-components";
import getImageURL from "../../../../../controllers/getImageURL";
import CustomButton from "../../../../helperComponents/CustomButton";
import Context from "../../../../../Context";
import AddOrEditForm from "../AddOrEditForm";
import { useTranslation } from "react-i18next";

const Card = styled.div`
  background: var(--translucent);
  border-radius: 15px;
  padding: 10px;
  width: 350px;
  overflow: hidden;
  position: relative;
  transition: all 0.25s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Image = styled.img`
  width: 100%;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const Name = styled.div`
  font-weight: 900;
  font-size: 19px;
`;

const Status = styled.div`
  /* padding: 5px 10px; */
  border-radius: 5px;
  text-transform: capitalize;
  font-weight: 500;
`;

const ProfileText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
  flex-direction: column;
`;

export default function ManageActivityBookingCard({
  item,
  index,
  items,
  setItems,
  forAddingNewItem,
}) {
  const { setForm } = useContext(Context);
  const { t } = useTranslation();

  let fields = [
    {
      type: "FILE",
      name: "reportFile",
      label: t("adminActivityBookingReportPDF"),
    },
    {
      type: "LONG_STRING",
      name: "remark",
      label: t("adminActivityBookingFormRemark"),
      placeholder: "Type remark here",
    },
    {
      type: "LONG_STRING",
      name: "remark2",
      label: t("adminActivityBookingFormRemark2"),
      placeholder: "Type remark here",
    },
    {
      type: "LONG_STRING",
      name: "remark3",
      label: t("adminActivityBookingFormRemark3"),
      placeholder: "Type remark here",
    },
  ];

  if (forAddingNewItem) return null;

  return (
    <Card>
      <ProfileContainer>
        {item.user ? (
          <Image
            src={getImageURL(item.user.profileImage, true)}
            alt="Profile Image"
          />
        ) : (
          <Image src={getImageURL(null, true)} alt="Profile Image" />
        )}

        <ProfileText>
          {item.user ? <Name>{item.user.name} </Name> : item.nonMemberEmail}

          <Status>
            {t("adminActivityBookingActivityType")}: {item.activityType.title}
          </Status>
        </ProfileText>
      </ProfileContainer>

      <ActionButtons>
        <CustomButton onClick={editItem}>
          {t("adminActivityBookingAddReport")}
        </CustomButton>
      </ActionButtons>
    </Card>
  );

  function editItem() {
    setForm({
      title: "Edit Item",
      component: (
        <AddOrEditForm
          arrayItems={items}
          setArrayItems={setItems}
          index={index}
          fields={fields}
          values={item}
          path="/admin/activity-booking"
        />
      ),
    });
  }
}
