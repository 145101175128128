import React, { useContext } from "react";
import styled from "styled-components";
import getImageURL from "../../../../../controllers/getImageURL";
import CustomButton from "../../../../helperComponents/CustomButton";
import Context from "../../../../../Context";
import AddOrEditForm from "../AddOrEditForm";
import { useTranslation } from "react-i18next";

const Card = styled.div`
  background: var(--translucent);
  border-radius: 15px;
  padding: 10px;
  width: 350px;
  overflow: hidden;
  position: relative;
  transition: all 0.25s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 20px;

  border: 1px solid rgba(255, 255, 255, 0.1);

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const NewCard = styled.div`
  background: var(--translucent);
  border-radius: 15px;
  padding: 10px;
  width: 350px;
  overflow: hidden;
  position: relative;
  transition: all 0.25s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  width: 100%;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const Name = styled.div`
  font-weight: 900;
  font-size: 19px;
`;

const Status = styled.div`
  /* padding: 5px 10px; */
  border-radius: 5px;
  text-transform: capitalize;
  font-weight: 500;
`;

const ProfileText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
  flex-direction: column;
`;

const Data = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FiledValue = styled.div``;

//deliveryDate
export default function ManageOrderCard({
  item,
  index,
  items,
  setItems,
  forAddingNewItem,
}) {
  const { setForm } = useContext(Context);
  const { t } = useTranslation();

  let statusOptions = [
    { value: "ORDER_PENDING", label: t("adminPageOrdersFormOrderPending") },
    { value: "ORDER_APPROVED", label: t("adminPageOrdersFormOrderApproved") },
    { value: "DISPATCHED", label: t("adminPageOrdersFormDispatched") },
    { value: "OUT_FOR_DELIVER", label: t("adminPageOrdersFormOutForDelivery") },
  ];

  let fields = [
    {
      type: "NUMBER",
      name: "quantity",
      label: t("adminPageOrdersFormQuantity"),
    },
    {
      type: "LONG_STRING",
      name: "address",
      label: t("adminPageOrdersFormAddress"),
    },
    {
      type: "OPTIONS",
      name: "status",
      label: t("adminPageOrdersFormStatus"),
      options: statusOptions,
    },
    {
      type: "UPCOMING_DATES",
      name: "deliveryDate",
      label: t("adminPageOrdersFormDeliveryDate"),
    },
  ];

  if (forAddingNewItem)
    return (
      <NewCard>
        <CustomButton onClick={addItem}>
          {" "}
          {t("clickHereToAddNew")}{" "}
        </CustomButton>
      </NewCard>
    );

  let images = item.item.images ? item.item.images : [];

  return (
    <Card>
      <ProfileContainer>
        <Image src={getImageURL(images[0], false)} alt="Image" />
        <ProfileText>
          <Name>{item.item.title} </Name>
        </ProfileText>
      </ProfileContainer>

      <Data>
        <FiledValue>
          {t("adminPageOrdersFormStatus")}: {t(item.status)}{" "}
        </FiledValue>
        <FiledValue>
          {t("adminPageOrdersFormQuantity")}: {item.quantity}{" "}
        </FiledValue>
        <FiledValue>
          {t("adminPageOrdersFormAddress")}: {item.address}{" "}
        </FiledValue>
        {item.note ? (
          <FiledValue>
            {t("adminPageOrdersFormNote")}: {item.note}{" "}
          </FiledValue>
        ) : null}
      </Data>

      <ActionButtons>
        <CustomButton onClick={editItem}>{t("editItem")}</CustomButton>
      </ActionButtons>
    </Card>
  );

  function addItem() {
    setForm({
      title: "Add Item",
      component: (
        <AddOrEditForm
          arrayItems={items}
          setArrayItems={setItems}
          fields={fields}
          path="/admin/order"
        />
      ),
    });
  }

  function editItem() {
    setForm({
      title: "Edit Item",
      component: (
        <AddOrEditForm
          arrayItems={items}
          setArrayItems={setItems}
          index={index}
          fields={fields}
          values={item}
          path="/admin/order"
        />
      ),
    });
  }
}
