import LoggedInBoilerplate from "../LoggedInBoilerplate";
import { useEffect, useState } from "react";
import LoadingSection from "../../../helperComponents/LoadingSection";
import { serverLine } from "../../../../controllers/serverLine";
import getUrlQuery from "../../../../controllers/getUrlQuery";
import ActivityDetails from "./EmployeeDetails";
import { useTranslation } from "react-i18next";
import EmployeeDetails from "./EmployeeDetails";

export default function EmployeePage() {
  const [item, setItem] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    let urlQuery = getUrlQuery();
    serverLine.get(`/employee/?itemID=${urlQuery.itemID}`).then(setItem);
  }, []);

  if (!item)
    return (
      <LoggedInBoilerplate>
        <LoadingSection />
      </LoggedInBoilerplate>
    );

  return (
    <LoggedInBoilerplate>
      <EmployeeDetails item={item} />
    </LoggedInBoilerplate>
  );
}
