import styled from "styled-components";
import formatDate from "../../../../../controllers/formatDate";
import CustomButton from "../../../../helperComponents/CustomButton";
import submitData from "../../../../../controllers/request/submitData";
import { useState } from "react";
import LoadingSection from "../../../../helperComponents/LoadingSection";
import { useTranslation } from "react-i18next";
import ExactSlotTime from "./ExactSlotTime";

const Card = styled.div`
  background: #111111;
  border-radius: 15px;
  width: 100%;
  overflow: hidden;
  color: white;
  position: relative;
  transition: all 0.25s ease-in-out;
`;

const InfoContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const JustText = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Title = styled.h2`
  font-size: 18px;
  font-weight: bold;
  color: #c9d400;
  margin: 0;
  margin-bottom: 5px;
`;

const Subtitle = styled.div`
  font-size: 16px;
  color: #808080;
  margin-top: 0;
`;

export default function ExistingCheckupBooking({ value }) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  if (loading) return <LoadingSection />;

  return (
    <Card>
      <InfoContainer>
        <JustText>
          <Title>{formatDate(value.date)}</Title>
          <Subtitle>
            {t("scheduleCheckupTime")}:{" "}
            <ExactSlotTime slot={value.slot} hour={value.hour} />
          </Subtitle>
          <Subtitle>
            {t("scheduleCheckupStatus")}: {value.status}
          </Subtitle>
        </JustText>

        {showCancelButton()}
      </InfoContainer>
    </Card>
  );

  function showCancelButton() {
    if (value.status == "PENDING")
      return (
        <CustomButton onClick={cancelBooking}>
          {t("scheduleCheckupCancelBooking")}
        </CustomButton>
      );

    return null;
  }

  function cancelBooking() {
    submitData({
      method: "post",
      path: "/cancel-health-checkup-booking",
      data: { id: value._id },
      onSuccess: () => {
        window.location.reload();
      },
      setLoading: setLoading,
    });
  }
}
