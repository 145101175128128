import { useTranslation } from "react-i18next";
import ListPage from "../ListPage";
import AnnouncementCard from "./AnnouncementCard";

export default function AnnouncementsPage() {
  const { t } = useTranslation();

  return (
    <ListPage
      path="/announcements"
      title={t("announcementsPageTitle")}
      CardComponent={AnnouncementCard}
    />
  );
}
