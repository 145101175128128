import { useContext, useEffect, useState } from "react";
import { serverLine } from "../../../../controllers/serverLine";
import LoggedInBoilerplate from "../LoggedInBoilerplate";
import LoadingSection from "../../../helperComponents/LoadingSection";
import Section from "../../../helperComponents/Section";
import styled from "styled-components";
import CustomButton from "../../../helperComponents/CustomButton";
import ActivityCard from "../activity/ActivityCard";
import AnnouncementSlideShowCard from "./AnnouncementSlideShowCard";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import AliceCarousel, { Link } from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import AliceCarouselLink from "../../../helperComponents/AliceCarouselLink";
import { useTranslation } from "react-i18next";
import Context from "../../../../Context";
import getImageURL from "../../../../controllers/getImageURL";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const UserImage = styled.img`
  height: 40px;
  border-radius: 100px;
  width: 40px;
  object-fit: cover;
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 900;
  display: flex;
  flex-direction: row;
  gap: 7px;
  text-transform: capitalize;
`;

const Hello = styled.div`
  opacity: 0.5;
`;

const Name = styled.div``;

export default function HelloUserSection() {
  const { loggedInUser } = useContext(Context);
  const { t } = useTranslation();
  let firstName = "";

  if (loggedInUser.name) {
    firstName = loggedInUser.name.split(" ")[0];
  }

  return (
    <Container>
      <UserImage src={getImageURL(loggedInUser.profileImage)} />
      <Text>
        <Hello>{t("hello")}!</Hello> <Name> {firstName}</Name>
      </Text>
    </Container>
  );
}
