import styled from "styled-components";
import CustomButton from "./CustomButton";
const Title = styled.div`
  font-size: 18px;
  color: var(--accentColor);
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
`;

const Button = styled.div`
  padding: 10px 15px;
  font-size: 19px;
  border-radius: 100px;
  background: var(--translucentLight);
  color: #fff;
  border: 1px solid var(--translucent);

  ${({ isSelected }) => {
    if (isSelected)
      return `
        background: var(--accentColor);
        color: var(--bgColor);
    `;
  }};

  cursor: pointer;

  transition: all 0.25s ease-in-out;

  &:hover {
    transform: scale(0.9);
  }

  @media (max-width: 900px) {
    font-size: 13px;
  }
`;

export default function BigToggle({ title, options }) {
  let theDisabledButtonStyle = { pointerEvent: "none", opacity: 0.5 };

  return (
    <Container>
      <Title>{title}</Title>
      <Row>
        {options.map((item) => (
          <Button
            style={item.onClick ? null : theDisabledButtonStyle}
            onClick={item.onClick}
            isSelected={item.isSelected}
          >
            {item.label}
          </Button>
        ))}
      </Row>
    </Container>
  );
}
