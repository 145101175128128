import styled from "styled-components";
import LoggedInBoilerplate from "../LoggedInBoilerplate";
import BigButton from "../BigButton";
import UpcomingActivitiesSection from "../activity/upcomingActivities/UpcomingActivitiesSection";
import AnnouncementSection from "../announcements/AnnouncementSection";
import { useTranslation } from "react-i18next";
import HelloUserSection from "../announcements/HelloUserSection";
import { useContext } from "react";
import Context from "../../../../Context";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 150px;
  /* margin-top: 50px; */

  @media (max-width: 900px) {
    gap: 50px;
  }
`;

const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 90vw;
  justify-content: space-between;
  gap: 50px;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 40px;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  gap: 30px;
  flex: 1;

  @media (max-width: 900px) {
    gap: 15px;
  }
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 300;
  color: var(--accentColor);
  opacity: 0.5;
`;
const List = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export default function HomePage() {
  const { isMobile } = useContext(Context);
  const { t } = useTranslation();

  let announcementButton = (
    <BigButton link="/announcements" icon="announcements.png">
      {t("menuItemAnnouncements")}
    </BigButton>
  );

  let shoppingButton = (
    <BigButton link="/shopping" icon="shopping.png">
      {t("menuItemShopping")}
    </BigButton>
  );

  let myActivitiesButton = (
    <BigButton link="/my-activities" icon="activities.png">
      {t("menuItemMyActivities")}
    </BigButton>
  );

  let myOrdersButton = (
    <BigButton link="/my-orders" icon="orders.png">
      {t("menuItemMyOrders")}
    </BigButton>
  );

  if (isMobile) {
    announcementButton = null;
    shoppingButton = null;
    myActivitiesButton = null;
    myOrdersButton = null;
  }

  return (
    <LoggedInBoilerplate>
      <Container>
        <FirstRow>
          <AnnouncementSection />

          <Section>
            <Title>{t("menu")}</Title>

            <List>
              <BigButton
                link="/book-health-checkup"
                icon="book-health-checkup.png"
              >
                {t("menuItemReserveHealthCheckUp")}
              </BigButton>

              <BigButton link="/health-reports" icon="health-reports.png">
                {t("menuItemHealthReports")}
              </BigButton>

              {myActivitiesButton}

              {myOrdersButton}

              {announcementButton}

              {shoppingButton}
            </List>
          </Section>
        </FirstRow>

        <UpcomingActivitiesSection />
      </Container>
    </LoggedInBoilerplate>
  );
}
