import { useContext, useState } from "react";
import OnboardingBoilerplate from "./OnboardingBoilerplate";
import MembershipSelection from "./MembershipSelection";
import { serverLine } from "../../../../controllers/serverLine";
import Context from "../../../../Context";
import { useTranslation } from "react-i18next";

export default function AskMembershipPage() {
  const { loggedInUser, setLoggedInUser } = useContext(Context);
  const [membershipType, setMembershipType] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  return (
    <OnboardingBoilerplate
      title={t("onboardingMembershipTitle")}
      desc={t("onboardingMembershipDesc")}
      onSubmit={onSubmit}
      loading={loading}
    >
      <MembershipSelection
        value={membershipType}
        onChange={setMembershipType}
      />
    </OnboardingBoilerplate>
  );

  async function onSubmit() {
    if (!membershipType)
      return window.popupAlert(t("onboardingMembershipWarning"));

    setLoading(true);

    try {
      await serverLine.patch("/me", { changes: { membershipType } });
      setLoading(false);
      let newLoggedInUser = { ...loggedInUser, membershipType };
      setLoggedInUser(newLoggedInUser);
      window.popupAlert("Saved");
    } catch (e) {
      window.popupAlert(e.message);
      setLoading(false);
    }
  }
}
