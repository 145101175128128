import { useContext, useState } from "react";

import CustomDateInput from "../../../helperComponents/CustomDateInput";
import OnboardingBoilerplate from "./OnboardingBoilerplate";
import extractEventValue from "../../../../controllers/utils/extractEventValue";
import CustomToggle from "../../../helperComponents/CustomToggle";
import MaterialInput from "../../../helperComponents/MaterialInput";
import styled from "styled-components";
import LoadingSection from "../../../helperComponents/LoadingSection";
import { serverLine } from "../../../../controllers/serverLine";
import Context from "../../../../Context";
import { useTranslation } from "react-i18next";

const options = [
  { value: "MALE", label: "Mr" },
  { value: "FEMALE", label: "Mme" },
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  width: 100%;
`;

export default function AskNameAndGenderPage() {
  const { loggedInUser, setLoggedInUser } = useContext(Context);
  const { t } = useTranslation();
  const [gender, setGender] = useState("MALE");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  return (
    <OnboardingBoilerplate
      title={t("onboardingNameGenderTitle")}
      desc={t("onboardingNameGenderDesc")}
      onSubmit={onSubmit}
      loading={loading}
    >
      <Container>
        <CustomToggle options={options} value={gender} onChange={setGender} />
        <MaterialInput
          label={t("onboardingNameInput")}
          // placeholder={"Please Type Your Name Here"}
          value={name}
          onChange={extractEventValue(setName)}
        />
      </Container>
    </OnboardingBoilerplate>
  );

  async function onSubmit() {
    if (!name) return window.popupAlert(t("onboardingNameGenderWarning"));
    if (name.length < 4)
      return window.popupAlert("Name should be at least 4 letters");

    setLoading(true);

    try {
      await serverLine.patch("/me", { changes: { gender, name } });
      setLoading(false);
      let newLoggedInUser = { ...loggedInUser, name, gender };
      setLoggedInUser(newLoggedInUser);
      window.popupAlert("Saved");
    } catch (e) {
      window.popupAlert(e.message);
      setLoading(false);
    }
  }
}
