import styled from "styled-components";
import ImagePicker from "../../../../editors/ImagePicker";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  align-items: flex-start;

  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export default function StoreImagesEditor({ value, onChange }) {
  if (!value) value = [];

  let uploadButtonStyle = {
    width: "100%",
    borderRadius: "10px",
  };

  let forPerson = false;
  return (
    <Container>
      <ImagePicker
        uploadButtonStyle={uploadButtonStyle}
        value={value[0]}
        onChange={setImage(0)}
        label="Image 1"
        forPerson={forPerson}
      />
      <ImagePicker
        uploadButtonStyle={uploadButtonStyle}
        value={value[1]}
        onChange={setImage(1)}
        label="Image 1"
        forPerson={forPerson}
      />
      <ImagePicker
        uploadButtonStyle={uploadButtonStyle}
        value={value[2]}
        onChange={setImage(2)}
        label="Image 1"
        forPerson={forPerson}
      />
      <ImagePicker
        uploadButtonStyle={uploadButtonStyle}
        value={value[3]}
        onChange={setImage(3)}
        label="Image 1"
        forPerson={forPerson}
      />
    </Container>
  );

  function setImage(index) {
    return (newImage) => {
      let newValue = [...value];
      newValue[index] = newImage;
      onChange(newValue);
    };
  }
}
