import { useTranslation } from "react-i18next";
import FooterPageBoilerplate from "./FooterPageBoilerplate";

export default function PrivacyPolicy() {
  const { t } = useTranslation();

  return (
    <FooterPageBoilerplate title={t("privacyPolicyTitle")}>
      {t("privacyPolicyDescription")}
    </FooterPageBoilerplate>
  );
}
