import "./App.css";

import { ThemeProvider, createTheme } from "@mui/material/styles/index.js";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CustomRouter from "./components/core/CustomRouter.js";
import Boilerplate from "./components/core/boilerplate/Boilerplate.js";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#D1DA30", // Change this to your desired primary color
    },
  },
});
//Material Theme made using: https://zenoo.github.io/mui-theme-creator/

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Boilerplate>
          <CustomRouter />
        </Boilerplate>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
