import { useContext, useEffect, useState } from "react";
import BigToggle from "../../../../helperComponents/BigToggle";
import Context from "../../../../../Context";
import LoadingSection from "../../../../helperComponents/LoadingSection";
import { serverLine } from "../../../../../controllers/serverLine";
import { useTranslation } from "react-i18next";
import addAdditionalZero from "../../../../../controllers/addAdditionalZero";

export default function ExactSlotTime({ slot, hour }) {
  const { adminSettings } = useContext(Context);

  if (!adminSettings) return null;

  let oneSlotVal = getValueOfOneSlotInMins();

  return `${addAdditionalZero(hour)}:${addAdditionalZero(oneSlotVal * (slot - 1))}`;

  function getValueOfOneSlotInMins() {
    let totalSlots = getNumberOfSlots();

    return Math.round(60 / totalSlots);
  }

  function getNumberOfSlots() {
    let slots = 4;

    if (adminSettings) {
      let data = adminSettings.data;

      if (data.healthCheckupSlotsPerHour) {
        slots = data.healthCheckupSlotsPerHour;
      }
    }

    return slots;
  }
}
