import styled from "styled-components";
import LoggedInBoilerplate from "../LoggedInBoilerplate";
import getUrlQuery from "../../../../controllers/getUrlQuery";
import { useContext, useEffect, useState } from "react";
import { serverLine } from "../../../../controllers/serverLine";
import LoadingSection from "../../../helperComponents/LoadingSection";
import getImageURL from "../../../../controllers/getImageURL";
import PrimaryButton from "../../../helperComponents/PrimaryButton";
import Context from "../../../../Context";
import { useTranslation } from "react-i18next";
import StoreItemImageGallery from "./StoreItemImageGallery";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: flex-start;
  padding: 40px 0;
  gap: 50px;
  background-color: #000;
  color: #fff;
  width: 100%;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const InfoSection = styled.div`
  max-width: 600px;
`;

const Title = styled.h1`
  font-size: 36px;
  color: #c9d400;
`;

const Title2 = styled.h1`
  font-size: 30px;
`;

const Description = styled.div`
  margin: 20px 0;
`;

const Subtitle = styled.div`
  font-size: 20px;
  margin-bottom: 10px;
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 1.5;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
`;

const Image = styled.img`
  width: 400px;
  border-radius: 0;
`;

const ImageSection = styled.div`
  display: flex;
  flex-direction: column;
  /* background: var(--translucent); */
  /* border: var(--highlightBorder); */
  border-radius: 10px;
  /* overflow: hidden; */
`;

const SmallHeading = styled.div`
  color: var(--accentColor);
  font-size: 23px;
`;

export default function StoreItemPage() {
  const { loggedInUser, setLoggedInUser } = useContext(Context);
  const { t } = useTranslation();
  const [item, setItem] = useState(null);
  const [addingToCart, setAddingToCart] = useState(false);

  useEffect(() => {
    let urlQuery = getUrlQuery();
    serverLine.get(`/store-item/?itemID=${urlQuery.itemID}`).then(setItem);
  }, []);

  if (!item || !loggedInUser)
    return (
      <LoggedInBoilerplate>
        <LoadingSection />
      </LoggedInBoilerplate>
    );

  let mainButton = (
    <PrimaryButton
      style={{ width: "100%", borderRadius: "10px" }}
      onClick={addToCart}
    >
      {t("addToCart")}
    </PrimaryButton>
  );

  if (addingToCart) mainButton = <LoadingSection />;

  return (
    <LoggedInBoilerplate>
      <Container>
        <RightSection>
          <ImageSection>
            <StoreItemImageGallery images={item.images} />
          </ImageSection>

          {mainButton}
        </RightSection>

        <InfoSection>
          <Title>{item.title} </Title>
          <Title2>CAF {item.price}</Title2>

          <Description>
            <Subtitle>
              {t("shoppingItemPageStock")}: {item.currentStock}
            </Subtitle>
            <Subtitle>{item.shortDescription}</Subtitle>
          </Description>

          <SmallHeading>{t("shoppingItemPageDescription")}</SmallHeading>
          <List>{item.description}</List>
        </InfoSection>
      </Container>
    </LoggedInBoilerplate>
  );

  async function addToCart() {
    if (!item.currentStock) return window.popupAlert(t("itemIsOutOfStock"));

    let itemID = item._id;

    let cartData = {};

    if (loggedInUser.cart) cartData = { ...loggedInUser.cart };

    let newQuantity = 0;

    if (cartData[itemID]) {
      cartData[itemID].quantity++;

      newQuantity = cartData[itemID].quantity;
    } else {
      newQuantity = 1;
      cartData[itemID] = { quantity: 1 };
    }

    if (newQuantity > item.currentStock)
      return window.popupAlert(t("thatManyStockIsNotAvailable"));

    setAddingToCart(true);
    try {
      await serverLine.patch("/me", { changes: { cart: cartData } });
      let newLoggedInUser = { ...loggedInUser, cart: cartData };

      setLoggedInUser(newLoggedInUser);
      window.popupAlert(t("addedToCart"));
    } catch (e) {
      window.popupAlert(e.message);
    }

    setAddingToCart(false);
  }
}
