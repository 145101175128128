import React, { useContext } from "react";
import styled from "styled-components";
import getImageURL from "../../../../../controllers/getImageURL";
import CustomButton from "../../../../helperComponents/CustomButton";
import Context from "../../../../../Context";
import AddOrEditForm from "../AddOrEditForm";
import formatDate from "../../../../../controllers/formatDate";
import getCategoryName from "../../../../../controllers/getCategoryName";
import { useTranslation } from "react-i18next";
import limitStringLength from "../../../../../controllers/limitStringLength";

const Card = styled.div`
  background: var(--translucent);
  border-radius: 15px;
  padding: 10px;
  width: 350px;
  overflow: hidden;
  position: relative;
  transition: all 0.25s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 20px;

  border: 1px solid rgba(255, 255, 255, 0.1);

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const NewCard = styled.div`
  background: var(--translucent);
  border-radius: 15px;
  padding: 10px;
  width: 350px;
  overflow: hidden;
  position: relative;
  transition: all 0.25s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  width: 100%;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const Name = styled.div`
  font-weight: 900;
  font-size: 19px;
`;

const Status = styled.div`
  /* padding: 5px 10px; */
  border-radius: 5px;
  text-transform: capitalize;
  font-weight: 500;
`;

const ProfileText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
  flex-direction: column;
`;

const Data = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FiledValue = styled.div``;

export default function ManageStoreItemCard({
  item,
  index,
  items,
  setItems,
  forAddingNewItem,
}) {
  const { setForm, adminSettings } = useContext(Context);
  const { t } = useTranslation();

  let fields = [
    {
      type: "STORE_IMAGES",
      name: "images",
      label: t("adminPageStoreFormImage"),
    },
    { type: "STRING", name: "title", label: t("adminPageStoreFormTitle") },
    {
      type: "REALLY_LONG_STRING",
      name: "description",
      label: t("adminPageStoreFormDescription"),
    },
    { type: "NUMBER", name: "price", label: t("adminPageStoreFormPrice") },
    {
      type: "NUMBER",
      name: "currentStock",
      label: t("adminPageStoreFormStock"),
    },
    {
      type: "NUMBER",
      name: "details.height",
      label: t("adminPageStoreFormHeight"),
    },
    {
      type: "NUMBER",
      name: "details.width",
      label: t("adminPageStoreFormWidth"),
    },
    {
      type: "CATEGORY_SELECTOR",
      name: "category",
      label: t("adminPageStoreFormSelectCategory"),
    },
  ];

  if (forAddingNewItem)
    return (
      <NewCard>
        <CustomButton onClick={addItem}>
          {" "}
          {t("adminPageStoreCreateNew")}{" "}
        </CustomButton>
      </NewCard>
    );

  let images = item.images ? item.images : [];

  return (
    <Card>
      <ProfileContainer>
        <Image src={getImageURL(images[0], false)} alt="Image" />
        <ProfileText>
          <Name>{item.title} </Name>
        </ProfileText>
      </ProfileContainer>

      <Data>
        <FiledValue>
          {t("adminPageStorePrice")}: {item.price}{" "}
        </FiledValue>
        <FiledValue>
          {t("adminPageStoreDescription")}:{" "}
          {limitStringLength(item.description, 100)}{" "}
        </FiledValue>
        <FiledValue>
          {t("adminPageStoreCurrentStock")}: {item.currentStock}{" "}
        </FiledValue>
        <FiledValue>
          {t("adminPageStoreCategory")}:{" "}
          {getCategoryName({ categoryID: item.category, adminSettings })}
        </FiledValue>
      </Data>

      <ActionButtons>
        <CustomButton onClick={editItem}>
          {t("adminPageStoreEditItem")}
        </CustomButton>
      </ActionButtons>
    </Card>
  );

  function addItem() {
    setForm({
      title: "Add Item",
      component: (
        <AddOrEditForm
          arrayItems={items}
          setArrayItems={setItems}
          fields={fields}
          path="/admin/store-item"
        />
      ),
    });
  }

  function editItem() {
    setForm({
      title: "Edit Item",
      component: (
        <AddOrEditForm
          arrayItems={items}
          setArrayItems={setItems}
          index={index}
          fields={fields}
          values={item}
          path="/admin/store-item"
        />
      ),
    });
  }
}
