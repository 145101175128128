import styled from "styled-components";
import BrandContainer from "../../../brand/BrandContainer";
import WithBackground from "../../../core/boilerplate/WithBackground";
import InputCard from "../../../helperComponents/InputCard";
import PrimaryButton from "../../../helperComponents/PrimaryButton";
import LoadingSection from "../../../helperComponents/LoadingSection";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  height: 100dvh;
  overflow-y: scroll;
  padding: 100px;
  width: 100vw;
`;

export default function OnboardingBoilerplate({
  children,
  onSubmit,
  title,
  desc,
  submitButtonText,
  loading,
}) {
  const { t } = useTranslation();

  if (loading) {
    children = <LoadingSection />;
    onSubmit = null;
  }

  return (
    <WithBackground backgroundImage={"/dumbbells.jpg"}>
      <Container>
        <BrandContainer />
        <InputCard
          title={title}
          desc={desc}
          onSubmit={onSubmit}
          submitButtonText={
            submitButtonText ? submitButtonText : t("onboardingSubmit")
          }
        >
          {children}
        </InputCard>
      </Container>
    </WithBackground>
  );
}
