import styled from "styled-components";

const Path = styled.path`
  /* stroke: var(--color); */
`;

const Path2 = styled.path`
  stroke: var(--color);
  fill: var(--color);
  stroke-width: 1;
`;

const SVG = styled.svg`
  height: 40px;
  width: 40px;
`;

export default function LogoSVG({ size }) {
  if (!size) size = "45px";

  return (
    <SVG
      style={{ height: size, width: size }}
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="22"
      viewBox="0 0 45 22"
      fill="none"
    >
      <Path
        d="M1.24072 11.0143C4.34576 17.1293 11.8001 23.1907 19.4324 20.6818C29.2958 17.4396 30.1242 0.0631098 43.9198 11.2346M1.24072 11.0143C4.34576 17.1293 11.5196 19.687 19.152 17.1782C29.0125 13.9369 31.3681 1.38053 43.9198 11.2346M1.24072 11.0143C4.3359 5.06587 11.7804 -0.846877 19.4168 1.57926C29.2855 4.7146 30.1418 22.1131 43.9198 11.2346M1.24072 11.0143C4.3359 5.06586 11.5055 2.40586 19.1419 4.832C29.0106 7.96735 30.1405 22.1284 43.9198 11.2346"
        stroke="white"
      />
    </SVG>
  );

  return (
    <SVG
      style={{ height: size, width: size }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
    >
      <Path
        d="M4.48911 17.759C1.88536 14.372 1.88551 9.62718 4.48958 6.24042C3.49745 6.77991 2.34013 6.90583 1.27088 6.61817C-0.423619 9.99501 -0.423627 14.0049 1.27086 17.3818C2.33991 17.0941 3.49705 17.2198 4.48911 17.759Z"
        fill="white"
      />
      <Path
        d="M6.24017 4.4898C9.62722 1.88543 14.3727 1.88546 17.7597 4.48989C17.2201 3.49769 17.0942 2.34024 17.3819 1.2709C14.0049 -0.42368 9.99479 -0.423632 6.61785 1.27104C6.90554 2.3403 6.77965 3.49765 6.24017 4.4898Z"
        fill="white"
      />
      <Path
        d="M19.5099 6.24014C22.1143 9.62704 22.1145 14.3723 19.5104 17.7593C20.5025 17.2199 21.6599 17.0941 22.7291 17.3819C24.4237 14.0049 24.4236 9.99487 22.729 6.61795C21.6596 6.90568 20.5022 6.77974 19.5099 6.24014Z"
        fill="white"
      />
      <Path
        d="M17.7592 19.5105C14.3725 22.1144 9.62778 22.1145 6.24094 19.5108C6.78013 20.5029 6.90586 21.66 6.61814 22.7291C9.99498 24.4236 14.0049 24.4236 17.3818 22.7292C17.094 21.66 17.2198 20.5026 17.7592 19.5105Z"
        fill="white"
      />
    </SVG>
  );
}
