import { GrGoogle } from "react-icons/gr";
import CustomButton from "./CustomButton";
import { styled } from "styled-components";
import goToAuthScreen from "../../controllers/goToAuthScreen";

const Container = styled.div`
  padding: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
`;



export default function LoginButton() {
  return (
    <CustomButton
      icon={<GrGoogle />}
      variant="outlined"
      circular={true}
      style={{ textTransform: "capitalize" }}
      onClick={goToAuthScreen}
    >
      Login With Google
    </CustomButton>
  );
}
