import { useTranslation } from "react-i18next";
import getUrlQuery from "../../../../../controllers/getUrlQuery";
import AdminCardsList from "../AdminCardsList";
import ManageActivityBookingCard from "./ManageActivityBookingCard";

export default function ManageActivityBookingsPage() {
  let urlQuery = getUrlQuery();
  const { t } = useTranslation();

  return (
    <AdminCardsList
      title={t("adminActivityBookingTitle")}
      path={`activity-bookings/${urlQuery.id}`}
      CardComponent={ManageActivityBookingCard}
    />
  );
}
