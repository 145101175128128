import { useContext, useEffect, useState } from "react";
import BigToggle from "../../../helperComponents/BigToggle";
import Context from "../../../../Context";
import { useTranslation } from "react-i18next";

export default function CategorySelector({ value, onChange, label }) {
  const { adminSettings } = useContext(Context);
  const { t } = useTranslation();

  if (!adminSettings) return null;
  if (!adminSettings.data) return null;
  if (!adminSettings.data.categories) return null;

  return (
    <BigToggle
      title={t("adminPageStoreSelectCategory")}
      options={adminSettings.data.categories.map((item) => getItem(item))}
    ></BigToggle>
  );

  function getItem(item) {
    return {
      onClick: () => {
        onChange(item._id);
      },
      isSelected: value === item._id,
      label: `${item.name}`,
    };
  }
}
