function logout() {
  localStorage.removeItem("wellness-token");
  localStorage.removeItem("wellness-userID");
  window.location = window.location.origin;

  // serverLine.delete("/cookie").then(() => {
  // window.location = window.location.origin;
  // });
}

export default logout;
