import styled from "styled-components";

import Link from "../../helperComponents/Link.js";
import { useTranslation } from "react-i18next";

const Img = styled.img`
  height: 35px;
  width: 35px;
  cursor: pointer;
  object-fit: contain;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  align-items: center;
  border-radius: 10px;
  height: 178px;

  width: 100%;

  color: var(--accentColor);

  background: linear-gradient(45deg, #d4cfcf1c, #000000, #c8c8c824);
  border: 1px solid #d1da303b;

  cursor: pointer;

  transition: all 0.25s ease-in-out;

  &:hover {
    transform: scale(0.9);
  }

  @media (max-width: 900px) {
    height: 135px;
    /* flex-direction: row; */
    gap: 14px;
  }
`;

const Text = styled.div`
  font-size: 14px;
  text-align: center;
  width: 60%;
  text-transform: capitalize;

  @media (max-width: 900px) {
    /* text-align: unset; */
  }
`;

export default function BigButton({ link, icon, children }) {
  const { t } = useTranslation();

  return (
    <Link href={link}>
      <Container>
        <Img src={"/button-icons/" + icon} />
        <Text>{children}</Text>
      </Container>
    </Link>
  );
}
