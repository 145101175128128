import CustomButton from "../../helperComponents/CustomButton";
import { styled } from "styled-components";
import goTo from "../../../controllers/goTo";
import { serverLine } from "../../../controllers/serverLine";
import { useContext, useState } from "react";

import {
  MdDeveloperMode,
  MdInterests,
  MdNoiseAware,
  MdPersonRemove,
} from "react-icons/md";

import { PiIntersectDuotone } from "react-icons/pi";
import Context from "../../../Context";
import { AiOutlineBarChart, AiOutlineUser } from "react-icons/ai";
import openPaymentPopup from "../../../controllers/payment/openPaymentPopup";
import { RiCoinLine, RiHeartLine } from "react-icons/ri";
import adminAccess from "../../../data/adminAccess";
import LoadingSection from "../../helperComponents/LoadingSection";
import logout from "../../../controllers/logout";
import { CgProfile } from "react-icons/cg";
import { LiaBanSolid } from "react-icons/lia";
import { BiStats } from "react-icons/bi";
import { HiHeart } from "react-icons/hi";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 100px;
  /* height: 100%; */
`;
const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
`;
const Types = styled.div`
  display: grid;

  grid-template-columns: 1fr;
  gap: 25px;
`;

const Message = styled.div`
  margin-top: 200px;
`;

export default function MoreMenu() {
  const { loggedInUser, loggedInUserID } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [loadingLogout, setLoadingLogout] = useState(false);

  if (!loggedInUserID) return <Message>Login to continue</Message>;

  let isAdmin = false;

  if (loggedInUser) {
    isAdmin = adminAccess.includes(loggedInUser.email);
  }

  if (loading || !loggedInUser) return <LoadingSection />;

  return (
    <Container>
      <Title>Options</Title>
      <Types>
        {isAdmin ? (
          <>
            <CustomButton
              variant={"filled"}
              href="/report-dashboard"
              icon={<MdNoiseAware />}
            >
              Reports Dashboard
            </CustomButton>

            <CustomButton
              variant={"filled"}
              href="/manage-bans"
              icon={<LiaBanSolid />}
            >
              Manage Bans
            </CustomButton>
          </>
        ) : null}

        <CustomButton variant={"filled"} href="/stats" icon={<BiStats />}>
          Stats
        </CustomButton>

        <CustomButton
          variant={"filled"}
          href="/edit-profile"
          icon={<CgProfile />}
        >
          Edit Profile
        </CustomButton>

        <CustomButton
          variant={"filled"}
          href="/edit-interests"
          icon={<RiHeartLine />}
        >
          Edit Interests
        </CustomButton>

        <CustomButton
          variant={"filled"}
          isLoading={loadingLogout}
          onClick={doLogout}
          icon={<MdPersonRemove />}
        >
          Logout
        </CustomButton>

        <CustomButton
          variant={"filled"}
          href="https://arnav.upon.one"
          icon={<MdDeveloperMode />}
        >
          About Developer
        </CustomButton>
      </Types>
    </Container>
  );

  async function doLogout() {
    setLoadingLogout(true);
    logout();
  }
}
