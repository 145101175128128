import React from "react";
import styled from "styled-components";
import Link from "../../helperComponents/Link";
import { useTranslation } from "react-i18next";

const Card = styled.div`
  padding: 13px 13px;
  border-radius: 10px;
  width: auto;
  color: white;
  height: 100%;
  background: linear-gradient(45deg, #d4cfcf1c, #000000, #c8c8c824);
  border: 1px solid #d1da303b;
  gap: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* align-items: center; */

  cursor: pointer;

  transition: all 0.25s ease-in-out;

  &:hover {
    transform: scale(0.9);
  }
`;

const Title = styled.h2`
  font-size: 15px;
  font-weight: 600;
  color: #c9d400;
  margin: 0;
  text-transform: capitalize;

  @media (max-width: 900px) {
    font-size: 10px;
  }
`;

const Img = styled.img`
  height: 20px;
`;

const MyActivitiesHeaderButton = () => {
  const { t } = useTranslation();

  return (
    <Link href="/my-activities">
      <Card>
        <Img src={"/button-icons/activities.png"} />

        <Title>{t("menuItemMyActivities")}</Title>
      </Card>
    </Link>
  );
};

export default MyActivitiesHeaderButton;
