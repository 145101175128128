import { GoArrowRight } from "react-icons/go";
import CustomButton from "./CustomButton";

export default function PrimaryButton({
  onClick,
  children,
  style = {},
  textStyle,
}) {
  if (!textStyle) textStyle = {};
  return (
    <CustomButton
      onClick={onClick}
      iconStyle={{ fontSize: "27px" }}
      textStyle={{ fontWeight: "900", fontSize: "20px", ...textStyle }}
      onHoverStyle={`

        transform:scale(0.9);

        `}
      style={{
        color: "var(--bgColor)",
        borderRadius: "100px",
        width: "200px",
        gap: "30px",
        border: "none",
        flexDirection: "row-reverse",
        fontWeight: "900",
        background: "linear-gradient(90deg, #ced400 0%, #75a500 100%)",
        boxShadow: "rgb(70, 96, 12) 5px 6px 0px",
        transition: "all 0.25s ease-in-out",
        ...style,
      }}
      icon={<GoArrowRight />}
    >
      {children}
    </CustomButton>
  );
}
