import { useEffect, useState } from "react";
import { serverLine } from "../../../../../controllers/serverLine";
import LoggedInBoilerplate from "../../LoggedInBoilerplate";
import LoadingSection from "../../../../helperComponents/LoadingSection";
import Section from "../../../../helperComponents/Section";
import styled from "styled-components";
import CustomButton from "../../../../helperComponents/CustomButton";
import ActivityCard from "../ActivityCard";
import { useTranslation } from "react-i18next";
import CustomMasonry from "../../../../helperComponents/CustomMasonry";

const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const TopLeft = styled.div`
  font-size: 22px;
  color: var(--accentColor);

  @media (max-width: 900px) {
    font-size: 18px;
    opacity: 0.7;
  }
`;

const TopRight = styled.div``;

const List = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export default function UpcomingActivitiesSection() {
  const [items, setItems] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    serverLine.get("/upcoming-activities").then(setItems);
  }, []);

  if (!items) return <LoadingSection />;

  let allItems = items.map((item) => <ActivityCard item={item} />);

  return (
    <Container>
      <Top>
        <TopLeft>{t("upcomingActivitiesTitle")}</TopLeft>
        <TopRight>
          <CustomButton href="/upcoming-activities">
            {t("upcomingActivitiesShowAll")}{" "}
          </CustomButton>
        </TopRight>
      </Top>
      <CustomMasonry
        maxCol={4}
        list={allItems}
        colsOnMobile={2}
      ></CustomMasonry>
    </Container>
  );
}
