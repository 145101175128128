import React, { useContext } from "react";
import styled from "styled-components";
import getImageURL from "../../../../controllers/getImageURL";
import formatDate from "../../../../controllers/formatDate";
import SemanticButton from "../../../helperComponents/SemanticButton";
import { useTranslation } from "react-i18next";
import Context from "../../../../Context";

const Card = styled.div`
  background: #111111;
  border-radius: 15px;
  width: 350px;
  overflow: hidden;
  color: white;
  position: relative;
  transition: all 0.25s ease-in-out;

  cursor: pointer;

  &:hover {
    transform: scale(0.95);
  }

  border: 1px solid rgba(255, 255, 255, 0.1);

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 250px;
  overflow: hidden;

  @media (max-width: 900px) {
    height: 100px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Badge = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: #303030;
  padding: 5px 15px;
  border-radius: 20px;
  font-size: 14px;
  color: #c9d400;
  font-weight: bold;

  @media (max-width: 900px) {
    font-size: 8px;
  }
`;

const InfoContainer = styled.div`
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 900px) {
    gap: 5px;
    padding: 15px 13px;
  }
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #c9d400;
  margin: 0;
  padding: 0;

  @media (max-width: 900px) {
    font-size: 13px;
    margin-bottom: 10px;
  }
`;

const Subtitle = styled.div`
  font-size: 16px;
  color: #808080;

  @media (max-width: 900px) {
    font-size: 12px;
  }
`;

const Badge2 = styled(Badge)`
  left: 15px;
  right: unset;
  background: #c9d400;
  color: #303030;
`;

export default function ActivityCard({ item }) {
  const { isMobile } = useContext(Context);
  const { t } = useTranslation();

  return (
    <SemanticButton
      style={isMobile ? { width: "100%" } : null}
      href={`/activity/?itemID=${item._id}`}
    >
      <Card>
        <ImageContainer>
          <Image src={getImageURL(item.activityType.image)} alt="" />

          {item.isBooked ? (
            <Badge>{t("upcomingActivitiesBooked")}</Badge>
          ) : null}
        </ImageContainer>
        <InfoContainer>
          <Title>{item.activityType.title}</Title>

          <Subtitle>{formatDate(item.date)}</Subtitle>

          <Subtitle>
            Time: {item.hour}:{item.mins}
          </Subtitle>

          <Subtitle>
            {" "}
            {t("upcomingActivitiesCoach")}: {item.employee.name}
          </Subtitle>
          <Subtitle>
            {" "}
            {t("upcomingActivitiesSeatsLeft")} Left: {item.seats}
          </Subtitle>
        </InfoContainer>
      </Card>
    </SemanticButton>
  );
}
