import { useTranslation } from "react-i18next";
import AdminCardsList from "../AdminCardsList";
import ManageEmployeesCard from "./ManageEmployeesCard";

export default function ManageEmployeesPage() {
  const { t } = useTranslation();

  return (
    <AdminCardsList
      title={t("adminPageEmployeesTitle")}
      path="employees"
      CardComponent={ManageEmployeesCard}
      allowCreation={true}
    />
  );
}
