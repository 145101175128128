export default function goToAuthScreen() {
    const clientId =
      "1012677660729-nd0ael59u41657lkq5v0itk029u2ch7e.apps.googleusercontent.com";
    const redirectUri = `${window.location.origin}/auth-redirect`;
    const responseType = "code";
    const scope = "profile email openid";
  
    let theLink = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=${responseType}&access_type=offline&include_granted_scopes=true`;
  
    // console.log(theLink);
    window.location = theLink;
  }