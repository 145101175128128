import styled from "styled-components";
import WithBackground from "../../../core/boilerplate/WithBackground";
import LoggedInHeader from "../LoggedInHeader";
import LoggedInMembershipSection from "./LoggedInMembershipSection";
import LoggedInBoilerplate from "../LoggedInBoilerplate";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  align-items: center;
  gap: 50px;
`;

const Title = styled.h2``;

export default function LoggedInMembershipPage() {
  const { t } = useTranslation();

  return (
    <LoggedInBoilerplate showBackgroundImage={true}>
      <Container>
        <LoggedInMembershipSection />
      </Container>
    </LoggedInBoilerplate>
  );
}
